import React from "react";
import EventRegEmp from "./event-emp";
import EventRegHotel from "./event-hotel";
import EventRegFood from "./event-food";
import EventRegTour from "./event-tour";

export default function EventDetail() {
  return (
    <div className="w-full text-left text-gray-900 p-7">
      <div className="w-full pb-4 border-b border-gray-200">
        <div className="my-3 font-sans text-4xl">
          6th Asian Gymnaestrada 2024
        </div>
        <div className="text-lg text-gray-500 ml-4"></div>
      </div>
      <EventRegEmp eventid={"1"} />
      <EventRegHotel eventid={"1"} />
      <EventRegFood eventid={"1"} />
      {/*<EventRegTour eventid={"1"} />*/}
    </div>
  );
}
