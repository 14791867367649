import React, { useContext, useEffect, useState } from "react";
import UserContext from "../../utils/context";
import Loading from "../loading";
import axios from "axios";
import { API } from "../../utils/config";
import ToastMessage from "../toast";
import moment from "moment";

export default function BookingFoodAdd({ hide }) {
  const [loading, setLoading] = useState(false);
  const context = useContext(UserContext);
  const [food, setFood] = useState([]);
  const [lunch, setLunch] = useState({
    foodId: 1,
    st: moment().format("YYYY-MM-DD"),
    ed: moment().format("YYYY-MM-DD"),
    foodNu: 0,
  });
  const [dinner, setDinner] = useState({
    foodId: 2,
    st: moment().format("YYYY-MM-DD"),
    ed: moment().format("YYYY-MM-DD"),
    foodNu: 0,
  });

  const addBooking = async () => {
    try {
      setLoading(true);
      var ishide = false;
      if (
        Math.floor(
          (new Date(lunch.ed).getTime() - new Date(lunch.st).getTime()) /
            (1000 * 60 * 60 * 24)
        ) > 0 ||
        lunch.foodNu > 0
      ) {
        if (
          Math.floor(
            (new Date(lunch.ed).getTime() - new Date(lunch.st).getTime()) /
              (1000 * 60 * 60 * 24)
          ) < 1
        ) {
          ToastMessage({
            type: "error",
            message: "Please choose dates for lunch",
          });
          return;
        }
        if (!lunch.foodNu && lunch.foodNu < 1) {
          ToastMessage({
            type: "error",
            message: "Please insert number of delegation",
          });
          return;
        }

        let frmdata = new FormData();
        frmdata.append("oper", "food");
        frmdata.append("userid", context.userid);
        frmdata.append("token", context.token);
        frmdata.append("eventid", "1");
        frmdata.append("foodId", lunch.foodId);
        frmdata.append("foodNu", lunch.foodNu);
        frmdata.append("st", lunch.st);
        frmdata.append("ed", lunch.ed);
        frmdata.append(
          "price",
          Math.floor(
            (new Date(lunch.ed).getTime() - new Date(lunch.st).getTime()) /
              (1000 * 60 * 60 * 24)
          ) *
            lunch.foodNu *
            food.filter((p) => p.fid === "1")[0]?.price
        );
        const res = await axios.post(API + "save.php", frmdata);
        if (res.data?.success) {
          ishide = true;
        } else {
          ToastMessage({ type: "error", message: res.data?.message });
        }
      }
      if (
        Math.floor(
          (new Date(dinner.ed).getTime() - new Date(dinner.st).getTime()) /
            (1000 * 60 * 60 * 24)
        ) > 0 ||
        dinner.foodNu > 0
      ) {
        if (
          Math.floor(
            (new Date(dinner.ed).getTime() - new Date(dinner.st).getTime()) /
              (1000 * 60 * 60 * 24)
          ) < 1
        ) {
          ToastMessage({
            type: "error",
            message: "Please choose dates for dinner",
          });
          return;
        }
        if (!lunch.foodNu && lunch.foodNu < 1) {
          ToastMessage({
            type: "error",
            message: "Please insert number of delegation",
          });
          return;
        }

        let frmdata = new FormData();
        frmdata.append("oper", "food");
        frmdata.append("userid", context.userid);
        frmdata.append("token", context.token);
        frmdata.append("eventid", "1");
        frmdata.append("foodId", dinner.foodId);
        frmdata.append("foodNu", dinner.foodNu);
        frmdata.append("st", dinner.st);
        frmdata.append("ed", dinner.ed);
        frmdata.append(
          "price",
          Math.floor(
            (new Date(dinner.ed).getTime() - new Date(dinner.st).getTime()) /
              (1000 * 60 * 60 * 24)
          ) *
            dinner.foodNu *
            food.filter((p) => p.fid === "1")[0]?.price
        );
        const res = await axios.post(API + "save.php", frmdata);
        if (res.data?.success) {
          ishide = true;
        } else {
          ToastMessage({ type: "error", message: res.data?.message });
        }
      }
      setLoading(false);
      if (ishide) {
        ToastMessage({
          type: "success",
          message: "Booking registered successfully",
        });
        hide(false);
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
      ToastMessage({ type: "error", message: "Error" });
    }
  };

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        let frmdata = new FormData();
        frmdata.append("oper", "list");
        frmdata.append("userid", context.userid);
        frmdata.append("token", context.token);
        const res = await axios.post(API + "food.php", frmdata);
        if (res.data?.success) {
          const info = JSON.parse(res.data.data);
          setFood(info);
          console.log(food);
        } else {
          ToastMessage({ type: "error", message: res.data?.message });
        }
        setLoading(false);
      } catch (error) {
        console.log(error);
        setLoading(false);
        ToastMessage({ type: "error", message: "Error" });
      }
    })();
  }, []);

  return (
    <div className="absolute top-0 left-0 w-full h-full">
      {loading && <Loading />}
      <div className="w-2/4 mx-auto bg-gray-100 mt-48 rounded-lg border min-h-96 shadow p-7">
        <div className="w-full grid grid-cols-2">
          <div>
            <div className=" text-2xl font-bold">Lunch</div>
            <div className="w-full flex mt-4">
              <div className="p-1 w-40">Date start : </div>
              <div>
                <input
                  type="date"
                  required
                  className="border px-2 py-1 rounded w-36 mx-1"
                  value={lunch.st}
                  onChange={(e) => {
                    setLunch({ ...lunch, st: e.target.value });
                  }}
                />
              </div>
            </div>
            <div className="w-full flex mt-4">
              <div className="p-1 w-40">Date to : </div>
              <div>
                <input
                  type="date"
                  required
                  className="border px-2 py-1 rounded w-36 ml-1"
                  value={lunch.ed}
                  onChange={(e) => {
                    setLunch({ ...lunch, ed: e.target.value });
                  }}
                />
              </div>
            </div>
            <div className="w-full flex mt-4">
              <div className="p-1 w-40">Number of Delegation: </div>
              <div>
                <input
                  type="number"
                  required
                  className="border px-2 py-1 rounded w-20 mx-1"
                  value={lunch.foodNu}
                  onChange={(e) => {
                    setLunch({ ...lunch, foodNu: e.target.value });
                  }}
                />
              </div>
            </div>
            <div className="w-full flex mt-4">
              <div className="p-1 w-40">Total days : </div>
              <div>
                <input
                  type="number"
                  required
                  readOnly
                  className="border px-2 py-1 rounded w-20 mx-1"
                  value={Math.floor(
                    (new Date(lunch.ed).getTime() -
                      new Date(lunch.st).getTime()) /
                      (1000 * 60 * 60 * 24)
                  )}
                />
              </div>
            </div>
            <div className="w-full flex mt-4">
              <div className="p-1 w-40">Price : </div>
              <div>
                <input
                  type="text"
                  readOnly
                  required
                  className="border px-2 py-1 rounded w-36 mx-1 text-right"
                  value={
                    (
                      Math.floor(
                        (new Date(lunch.ed).getTime() -
                          new Date(lunch.st).getTime()) /
                          (1000 * 60 * 60 * 24)
                      ) *
                      lunch.foodNu *
                      food.filter((p) => p.fid === "1")[0]?.price
                    ).toLocaleString() + " USD"
                  }
                />
              </div>
            </div>
          </div>
          <div>
            <div className=" text-2xl font-bold">Dinner</div>
            <div className="w-full flex mt-4">
              <div className="p-1 w-40">Date start : </div>
              <div>
                <input
                  type="date"
                  required
                  className="border px-2 py-1 rounded w-36 mx-1"
                  value={dinner.st}
                  onChange={(e) => {
                    setDinner({ ...dinner, st: e.target.value });
                  }}
                />
              </div>
            </div>
            <div className="w-full flex mt-4">
              <div className="p-1 w-40">Date to : </div>
              <div>
                <input
                  type="date"
                  required
                  className="border px-2 py-1 rounded w-36 ml-1"
                  value={dinner.ed}
                  onChange={(e) => {
                    setDinner({ ...dinner, ed: e.target.value });
                  }}
                />
              </div>
            </div>
            <div className="w-full flex mt-4">
              <div className="p-1 w-40">Number of Delegation: </div>
              <div>
                <input
                  type="number"
                  required
                  className="border px-2 py-1 rounded w-20 mx-1"
                  value={dinner.foodNu}
                  onChange={(e) => {
                    setDinner({ ...dinner, foodNu: e.target.value });
                  }}
                />
              </div>
            </div>
            <div className="w-full flex mt-4">
              <div className="p-1 w-40">Total days : </div>
              <div>
                <input
                  type="number"
                  required
                  readOnly
                  className="border px-2 py-1 rounded w-20 mx-1"
                  value={Math.floor(
                    (new Date(dinner.ed).getTime() -
                      new Date(dinner.st).getTime()) /
                      (1000 * 60 * 60 * 24)
                  )}
                />
              </div>
            </div>
            <div className="w-full flex mt-4">
              <div className="p-1 w-40">Price : </div>
              <div>
                <input
                  type="text"
                  readOnly
                  required
                  className="border px-2 py-1 rounded w-36 mx-1 text-right"
                  value={
                    (
                      Math.floor(
                        (new Date(dinner.ed).getTime() -
                          new Date(dinner.st).getTime()) /
                          (1000 * 60 * 60 * 24)
                      ) *
                      dinner.foodNu *
                      food.filter((p) => p.fid === "2")[0]?.price
                    ).toLocaleString() + " USD"
                  }
                />
              </div>
            </div>
          </div>
        </div>
        <div className="w-full flex mt-4 justify-end">
          <div className="p-1 w-40 text-right font-bold">TOTAL : </div>
          <div>
            <input
              type="text"
              readOnly
              required
              className="border px-2 py-1 rounded w-40 mx-1 text-right"
              value={
                (
                  Math.floor(
                    (new Date(lunch.ed).getTime() -
                      new Date(lunch.st).getTime()) /
                      (1000 * 60 * 60 * 24)
                  ) *
                    lunch.foodNu *
                    food.filter((p) => p.fid === "1")[0]?.price +
                  Math.floor(
                    (new Date(dinner.ed).getTime() -
                      new Date(dinner.st).getTime()) /
                      (1000 * 60 * 60 * 24)
                  ) *
                    dinner.foodNu *
                    food.filter((p) => p.fid === "2")[0]?.price
                ).toLocaleString() + " USD"
              }
            />
          </div>
        </div>
        <div className="w-full flex gap-4 mt-4">
          <div className=" w-36">
            <input
              type="button"
              value="Book"
              onClick={() => {
                addBooking();
              }}
              className="MyBgColorSky rounded py-2 w-full cursor-pointer MyWhite"
            />
          </div>
          <div className=" w-36">
            <input
              type="button"
              onClick={() => {
                hide(false);
              }}
              value="Cancel"
              className="MyBgColorRed rounded py-2 w-full cursor-pointer MyWhite"
            />
          </div>
        </div>
      </div>
    </div>
  );
}
