import { useContext, useEffect, useState } from "react";
import ToastMessage from "../toast";
import axios from "axios";
import { API } from "../../utils/config";
import EventAdd from "./event-add";
import EventEdit from "./event-edit";
import Loading from "../loading";
import UserContext from "../../utils/context";

export default function EventList(props) {
  const [events, setEvents] = useState([]);
  const [selected, setSelected] = useState(null);
  const [isload, setIsload] = useState(0);
  const [showAdd, setShowAdd] = useState(false);
  const [showEdit, setShowEdit] = useState(false);
  const context = useContext(UserContext);
  const [loading, setLoading] = useState(false);

  const activate = async (eventid, isactive) => {
    try {
      setLoading(true);
      let data = new FormData();
      if (isactive === 1) {
        data.append("oper", "disable");
      } else {
        data.append("oper", "activate");
      }
      data.append("evntId", eventid);
      data.append("token", context.token);
      const res = await axios.post(API + "event.php", data);
      if (res.data.success) {
        setIsload(isload + 1);
        ToastMessage({ type: "success", message: res.data.message });
      } else {
        ToastMessage({ type: "error", message: res.data.message });
      }
      setLoading(false);
    } catch (err) {
      console.log(err);
      setLoading(false);
      ToastMessage({ type: "error", message: "Error" });
    }
  };

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        let data = new FormData();
        data.append("oper", "list");
        data.append("token", context.token);
        const res = await axios.post(API + "event.php", data);
        if (res.data.success) {
          setEvents(JSON.parse(res.data.data));
        } else {
          ToastMessage({ type: "error", message: res.data.message });
        }
        setLoading(false);
      } catch (err) {
        setLoading(false);
        console.log(err);
        ToastMessage({ type: "error", message: "Error" });
      }
    })();
  }, [isload]);
  return (
    <div className="w-full mt-3">
      <div className="w-full text-right p-2">
        {loading && <Loading />}
        {showAdd && (
          <EventAdd
            isload={isload}
            setIsload={setIsload}
            setShow={setShowAdd}
          />
        )}
        {showEdit && (
          <EventEdit
            event={selected}
            isload={isload}
            setIsload={setIsload}
            setShow={setShowEdit}
          />
        )}
        <button
          className="bg-blue-500 border border-blue-600 rounded py-1 px-3 text-white"
          onClick={() => {
            setShowAdd(true);
          }}
        >
          Create Event
        </button>
      </div>
      <table width="100%" border={1}>
        <thead>
          <tr className="border-b border-gray-300">
            <th align="left">#</th>
            <th align="left">Event Name</th>
            <th align="left">Start</th>
            <th align="left">End</th>
            <th align="center">Active</th>
            <th align="left">Description</th>
            <th align="center">Edit</th>
          </tr>
        </thead>
        <tbody>
          {events.map((e, i) => {
            return (
              <tr key={i} className="border-b border-gray-300">
                <td>{i + 1}</td>
                <td>{e.evntNa}</td>
                <td>{e.evntSt}</td>
                <td>{e.evntEd}</td>
                <td align="center">
                  <button
                    onClick={() => {
                      activate(e.evntId, parseInt(e.isactive));
                    }}
                    className="bg-blue-500 border border-blue-600 rounded py-0 px-2 text-white"
                  >
                    {parseInt(e.isactive) === 1 ? "YES" : "NO"}
                  </button>
                </td>
                <td>{e.descr}</td>
                <td align="center" className="p-1">
                  <button
                    onClick={() => {
                      setSelected(e);
                      setShowEdit(true);
                    }}
                    className="bg-green-500 border border-green-600 rounded py-1 px-3 text-white"
                  >
                    Edit
                  </button>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
}
