import React, { useContext, useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import UserContext from "../../utils/context";
import EventDetail from "../eventreg/event-detail";
import Bookings from "./Bookings";
import Event from "./Event";
import Invoice from "./Invoice";
import Profile from "./Profile";
import Settings from "./Settings";
import RegMainMenu from "./regmainmenu";

export default function RegistrationHome() {
  const context = useContext(UserContext);
  const [searchParams] = useSearchParams();
  const route = useNavigate();

  useEffect(() => {
    if (!context.userid || context.userid === "") {
      route("/");
    }
  }, [context.userid]);

  return (
    <div className="w-full h-screen grid grid-cols-6 min-w-[1024px]">
      <div className="bg-[url('about2.jpg')] bg-center bg-no-repeat bg-cover h-full">
        <img
          src="/logo.jpeg"
          alt=""
          width={150}
          className="mx-auto rounded-b-3xl"
        />
      </div>
      <div className="col-span-5">
        <RegMainMenu />
        {searchParams.get("page")?.toString() === "profile" ? (
          <Profile />
        ) : searchParams.get("page")?.toString() === "event" ? (
          <Event />
        ) : searchParams.get("page")?.toString() === "booking" ? (
          <Bookings />
        ) : searchParams.get("page")?.toString() === "dashboard" ? (
          <EventDetail />
        ) : searchParams.get("page")?.toString() === "invoice" ? (
          <Invoice />
        ) : searchParams.get("page")?.toString() === "setting" ? (
          <Settings />
        ) : (
          "Unknown page"
        )}
      </div>
    </div>
  );
}
