import axios from "axios";
import { useContext, useEffect, useState } from "react";
import { API } from "../../utils/config";
import UserContext from "../../utils/context";
import Loading from "../loading";
import ToastMessage from "../toast";
import HotelPriceAdd from "./hotel-price-add";
import HotelPriceEdit from "./hotel-price-edit";

export default function HotelPriceList({ setShow, hotel }) {
  const context = useContext(UserContext);
  const [loading, setLoading] = useState(false);
  const [showAdd, setShowAdd] = useState(false);
  const [showEdit, setShowEdit] = useState(false);
  const [isload, setIsload] = useState(0);
  const [prices, setPrices] = useState([]);
  const [selected, setSelected] = useState(null);

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        let data = new FormData();
        data.append("oper", "prices");
        data.append("token", context.token);
        const res = await axios.post(API + "hotel.php", data);
        if (res.data.success) {
          setPrices(JSON.parse(res.data.data));
        } else {
          ToastMessage({ type: "error", message: res.data.message });
        }
        setLoading(false);
      } catch (err) {
        setLoading(false);
        console.log(err);
        ToastMessage({ type: "error", message: "Error" });
      }
    })();
  }, [isload]);

  const deletePrice = async (id) => {
    if (window.confirm("Are you sure to delete?")) {
      try {
        setLoading(true);
        let data = new FormData();
        data.append("oper", "pricedelete");
        data.append("token", context.token);
        data.append("id", id);
        const res = await axios.post(API + "hotel.php", data);
        if (res.data.success) {
          setIsload(isload + 1);
        } else {
          ToastMessage({ type: "error", message: res.data.message });
        }
        setLoading(false);
      } catch (err) {
        setLoading(false);
        console.log(err);
        ToastMessage({ type: "error", message: "Error" });
      }
    }
  };

  return (
    <div className="bg-gray-50 w-full h-full absolute top-0 left-0 pt-40 bg-opacity-90">
      <div className="bg-white max-w-5xl mx-auto p-10 text-2xl rounded border shadow text-black">
        <div className="w-full text-right p-2">
          {loading && <Loading />}
          {showAdd && (
            <HotelPriceAdd
              hid={hotel.hid}
              isload={isload}
              setIsload={setIsload}
              setShow={setShowAdd}
            />
          )}
          {showEdit && (
            <HotelPriceEdit
              record={selected}
              isload={isload}
              setIsload={setIsload}
              setShow={setShowEdit}
            />
          )}
          <button
            onClick={() => {
              setShowAdd(true);
            }}
            className="bg-blue-500 border border-blue-600 rounded py-1 px-3 text-white"
          >
            Add new Room Type
          </button>

          <button
            onClick={() => {
              setShow(false);
            }}
            className="bg-red-500 border border-red-600 rounded py-1 px-3 text-white ml-4"
          >
            Close
          </button>
        </div>
        <table width="100%" border={1}>
          <thead>
            <tr className="border-b border-gray-300">
              <th align="left">#</th>
              <th align="left">Room Type</th>
              <th align="left">Price</th>
              <th align="center">Edit</th>
              <th align="center">Delete</th>
            </tr>
          </thead>
          <tbody>
            {prices
              .filter((p) => p.hotelId === hotel.hid)
              .map((e, i) => {
                return (
                  <tr key={i} className="border-b border-gray-300">
                    <td align="left">{i + 1}</td>
                    <td align="left">{e.roomType}</td>
                    <td align="left">{e.price}</td>
                    <td align="center" className="p-1">
                      <button
                        onClick={() => {
                          setSelected(e);
                          setShowEdit(true);
                        }}
                        className="bg-green-500 border border-green-600 rounded py-1 px-3 text-white"
                      >
                        Edit
                      </button>
                    </td>
                    <td align="center" className="p-1">
                      <button
                        className="bg-red-500 border border-red-600 rounded py-1 px-3 text-white"
                        onClick={() => {
                          deletePrice(e.id);
                        }}
                      >
                        Delete
                      </button>
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </table>
      </div>
    </div>
  );
}
