import { API } from "../../utils/config";
import axios from "axios";
import { useContext, useEffect, useState } from "react";
import * as XLSX from "xlsx";
import ToastMessage from "../toast";
import Loading from "../loading";
import UserContext from "../../utils/context";

export default function EventRegHotel({ eventid }) {
  const [data, setData] = useState([]);
  const context = useContext(UserContext);
  const [loading, setLoading] = useState(false);

  const download = () => {
    const xl = data?.map((d, i) => ({
      id: i + 1,
      Category: "Category " + d.type,
      Hotel: d.hotelNa,
      RoomType: d.roomtype,
      Start: d.hotelSt,
      End: d.hotelEd,
      Number: d.roomNu,
      Price: d.price,
      EMail: d.email,
      Registered: d.crtdDt,
    }));
    const worksheet = XLSX.utils.json_to_sheet(xl);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Delegations");
    XLSX.writeFile(workbook, "Hotels.xlsx");
  };

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        let data = new FormData();
        data.append("oper", "hotel");
        data.append("evntId", eventid);
        data.append("token", context.token);
        const res = await axios.post(API + "eventreg.php", data);
        if (res.data.success) {
          setData(JSON.parse(res.data.data));
        } else {
          ToastMessage({ type: "error", message: res.data.message });
        }
        setLoading(false);
      } catch (err) {
        console.log(err);
        setLoading(false);
        ToastMessage({ type: "error", message: "Error" });
      }
    })();
  }, [eventid]);
  return (
    <div className="w-full">
      {loading && <Loading />}
      <h1 className="text-3xl my-2 text-red-400">Hotel orders</h1>
      <div className="w-full text-right">
        <button
          className="bg-green-600 border border-green-700 rounded py-1 px-4 text-sm text-white cursor-pointer"
          onClick={download}
        >
          Export data
        </button>
      </div>
      <table border={1} width="100%">
        <thead>
          <tr>
            <th align="left">#</th>
            <th align="left">Type</th>
            <th align="left">Hotel</th>
            <th align="left">Room Type</th>
            <th align="left">Start</th>
            <th align="left">End</th>
            <th align="left">Number</th>
            <th align="left">Price</th>
            <th align="left">E-Mail</th>
            <th align="left">Registered</th>
          </tr>
        </thead>
        <tbody>
          {data?.map((d, i) => {
            return (
              <tr key={i} className="text-sm border-b">
                <td className="p-1 text-left">{i + 1}</td>
                <td className="p-1 text-left">Category {d.type}</td>
                <td className="p-1 text-left">{d.hotelNa}</td>
                <td className="p-1 text-left">{d.roomtype}</td>
                <td className="p-1 text-left">{d.hotelSt}</td>
                <td className="p-1 text-left">{d.hotelEd}</td>
                <td className="p-1 text-left">{d.roomNu}</td>
                <td className="p-1 text-left">{d.price} USD</td>
                <td className="p-1 text-left">{d.email}</td>
                <td className="p-1 text-left">{d.crtdDt}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
}
