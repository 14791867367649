import React from "react";

export default function Loading() {
  return (
    <div className="bg-gray-50 w-full h-full absolute top-0 left-0 pt-40 bg-opacity-90">
      <div className="w-full mx-auto text-2xl text-center text-red-300">
        <img
          src={"/loading.gif"}
          width={200}
          height={200}
          alt=""
          className="mx-auto "
        />
        Loading...
      </div>
    </div>
  );
}
