import { FormEventHandler, useContext, useEffect, useState } from "react";
import ToastMessage from "../toast";
import axios from "axios";
import { API } from "../../utils/config";
import Loading from "../loading";
import UserContext from "../../utils/context";

export default function FoodEdit({ isload, setIsload, setShow, food }) {
  const [fid, setFid] = useState("");
  const [foodNa, setFoodNa] = useState("");
  const [price, setPrice] = useState(0);
  const context = useContext(UserContext);
  const [loading, setLoading] = useState(false);

  const UpdateFood = async (e) => {
    e.preventDefault();
    if (foodNa === "") {
      ToastMessage({ type: "error", message: "Insert Food name" });
      return;
    }
    setLoading(true);
    let formdata = new FormData();
    formdata.append("fid", fid);
    formdata.append("foodNa", foodNa);
    formdata.append("price", price.toString());
    formdata.append("token", context.token);
    formdata.append("oper", "update");
    try {
      const res = await axios.post(API + "food.php", formdata);
      if (res.data.success) {
        setIsload(isload + 1);
        setShow(false);
      } else {
        ToastMessage({ type: "error", message: res.data.message });
      }
      setLoading(false);
    } catch (err) {
      console.log(err);
      setLoading(false);
      ToastMessage({ type: "error", message: "Error" });
    }
  };

  useEffect(() => {
    setFid(food.fid);
    setFoodNa(food.foodNa);
    setPrice(food.price);
  }, []);
  return (
    <div className="bg-gray-50 w-full h-full absolute top-0 left-0 pt-40 bg-opacity-90">
      <div className="bg-white max-w-5xl mx-auto p-10 text-2xl rounded border shadow text-black">
        <div className="w-full text-left">
          {loading && <Loading />}
          <form onSubmit={UpdateFood}>
            <div className="my-3 w-full flex">
              <div className="min-w-40 p-2">Name : </div>
              <input
                type="text"
                name="foodNa"
                value={foodNa}
                onChange={(e) => {
                  setFoodNa(e.target.value);
                }}
                required
                placeholder="Food Name"
                className="border p-2 rounded w-full"
              />
            </div>
            <div className="my-3 w-full flex">
              <div className="min-w-40 p-2">Price : </div>
              <input
                type="number"
                name="price"
                required
                value={price}
                onChange={(e) => {
                  setPrice(parseInt(e.target.value));
                }}
                className="border p-2 rounded w-full"
              />
            </div>
            <div className="my-3 w-full flex justify-end gap-4">
              <input
                type="button"
                value="Cancel"
                onClick={() => {
                  setShow(false);
                }}
                className="bg-red-600 border border-red-700 rounded py-3 px-10 cursor-pointer"
              />
              <input
                type="submit"
                value="Update Food"
                className="bg-green-600 border border-green-700 rounded py-3 px-10 cursor-pointer"
              />
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
