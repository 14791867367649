import { API } from "../../utils/config";
import axios from "axios";
import { useContext, useEffect, useState } from "react";
import ToastMessage from "../toast";
import EventDetail from "./event-detail";
import Loading from "../loading";
import { TEvent } from "../event/event-list";
import UserContext from "../../utils/context";

export default function SelectEvent() {
  const [events, setEvents] = useState([]);
  const [showd, setShowd] = useState(false);
  const [selected, setSelected] = useState(null);
  const context = useContext(UserContext);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        let data = new FormData();
        data.append("oper", "list");
        data.append("token", context.token);
        const res = await axios.post(API + "event.php", data);
        if (res.data.success) {
          setEvents(JSON.parse(res.data.data));
        } else {
          ToastMessage({ type: "error", message: res.data.message });
        }
        setLoading(false);
      } catch (err) {
        console.log(err);
        setLoading(false);
        ToastMessage({ type: "error", message: "Error" });
      }
    })();
  }, []);

  return (
    <div className="w-full mt-3">
      {loading && <Loading />}
      {showd && <EventDetail event={selected} setShow={setShowd} />}
      <table width="100%" border={1}>
        <thead>
          <tr className="border-b border-gray-300">
            <th align="left">#</th>
            <th align="left">Event Name</th>
            <th align="left">Start</th>
            <th align="left">End</th>
            <th align="center">Active</th>
            <th align="left">Description</th>
          </tr>
        </thead>
        <tbody>
          {events.map((e, i) => {
            return (
              <tr key={i} className="border-b border-gray-300">
                <td>{i + 1}</td>
                <td>
                  <button
                    onClick={() => {
                      setSelected(e);
                      setShowd(true);
                    }}
                    className="bg-blue-500 border border-blue-600 rounded py-0 px-2 text-white"
                  >
                    {e.evntNa}
                  </button>
                </td>
                <td>{e.evntSt}</td>
                <td>{e.evntEd}</td>
                <td align="center">
                  {parseInt(e.isactive) === 1 ? "YES" : "NO"}
                </td>
                <td>{e.descr}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
}
