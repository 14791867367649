import React from "react";
import { useState } from "react";
import ToastMessage from "./toast";
import axios from "axios";
import { API } from "../utils/config";
import Loading from "./loading";

import { useNavigate } from "react-router-dom";

export default function SignUpScreen() {
  const [mail, setMail] = useState("");
  const [loading, setLoading] = useState(false);
  const route = useNavigate();

  const getpass = async (e) => {
    e.preventDefault();
    if (mail === "") {
      ToastMessage({ type: "error", message: "Enter mail address" });
      return;
    }
    try {
      setLoading(true);
      let data = new FormData();
      data.append("oper", "getpass");
      data.append("email", mail);
      const res = await axios.post(API + "login.php", data);
      if (res.data?.success) {
        ToastMessage({
          type: "success",
          message: res.data?.message,
        });
        route("/");
      } else {
        ToastMessage({ type: "error", message: res.data?.message });
      }
      setLoading(false);
    } catch (err) {
      ToastMessage({ type: "error", message: "Error" });
      console.log(err);
      setLoading(false);
    }
  };

  return (
    <div className="w-full grid grid-cols-4">
      <div>
        {loading && <Loading />}
        <div className="w-full">
          <img src="/logo.jpeg" alt="" width={300} className="mx-auto" />
        </div>
        <div className="w-full px-7">
          <form onSubmit={getpass}>
            <div className="my-3 w-full flex">
              <div className="min-w-28 p-2 mytextb">E-Mail : </div>
              <input
                type="email"
                name="email"
                required
                value={mail}
                onChange={(e) => {
                  setMail(e.target.value);
                }}
                placeholder="E-Mail address"
                className="border px-2 py-0 rounded w-full text-gray-900"
              />
            </div>
            <div className="my-3 w-full flex">
              <input
                type="submit"
                value="Sign Up"
                className="MyBgColorSky border border-sky-400 rounded py-2 w-full cursor-pointer MyWhite"
              />
            </div>
          </form>
        </div>
      </div>
      <div className=" col-span-3 bg-[url('bg.jpeg')] bg-center bg-no-repeat bg-cover h-[100vh]"></div>
    </div>
  );
}
