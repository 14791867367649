import React, { useContext, useEffect, useState } from "react";
import UserContext from "../../utils/context";
import Loading from "../loading";
import axios from "axios";
import { API } from "../../utils/config";
import ToastMessage from "../toast";
import _ from "lodash";

export default function BookingHotelAdd({ hide }) {
  const [selected, setSelected] = useState({
    category: "A",
    hotel: "",
    roomType: "",
    checkIn: "",
    checkOut: "",
    roomNumber: 1,
    price: 0,
  });
  const [hotels, setHotels] = useState([]);
  const [prices, setPrices] = useState([]);
  const [loading, setLoading] = useState(false);
  const context = useContext(UserContext);

  const addBooking = async () => {
    if (!selected.hotel && selected.hotel === "") {
      ToastMessage({ type: "error", message: "Please select hotel" });
      return;
    }
    if (!selected.roomType && selected.roomType === "") {
      ToastMessage({ type: "error", message: "Please select room type" });
      return;
    }
    if (!selected.checkIn && selected.checkIn === "") {
      ToastMessage({ type: "error", message: "Please select check in date" });
      return;
    }
    if (!selected.checkOut && selected.checkOut === "") {
      ToastMessage({ type: "error", message: "Please select check out date" });
      return;
    }
    if (!selected.roomNumber && selected.roomNumber < 1) {
      ToastMessage({ type: "error", message: "Please insert number of room" });
      return;
    }
    try {
      setLoading(true);
      let frmdata = new FormData();
      frmdata.append("oper", "hotel");
      frmdata.append("userid", context.userid);
      frmdata.append("token", context.token);
      frmdata.append("eventid", "1");
      frmdata.append("hotelId", selected.hotel);
      frmdata.append("hotelSt", selected.checkIn);
      frmdata.append("hotelEd", selected.checkOut);
      frmdata.append("type", selected.category);
      frmdata.append("roomtype", selected.roomType);
      frmdata.append("roomNu", selected.roomNumber);
      frmdata.append(
        "price",
        Math.floor(
          (new Date(selected.checkOut).getTime() -
            new Date(selected.checkIn).getTime()) /
            (1000 * 60 * 60 * 24)
        ) *
          prices.filter(
            (p) =>
              p.hotelId === selected.hotel && p.roomType === selected.roomType
          )[0].price *
          selected.roomNumber
      );
      const res = await axios.post(API + "save.php", frmdata);
      if (res.data?.success) {
        ToastMessage({ type: "success", message: res.data?.message });
        hide(false);
      } else {
        ToastMessage({ type: "error", message: res.data?.message });
      }
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
      ToastMessage({ type: "error", message: "Error" });
    }
  };

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        let frmdata = new FormData();
        frmdata.append("oper", "list");
        frmdata.append("userid", context.userid);
        frmdata.append("token", context.token);
        const res = await axios.post(API + "hotel.php", frmdata);
        if (res.data?.success) {
          const info = JSON.parse(res.data.data);
          setHotels(info);
        } else {
          ToastMessage({ type: "error", message: res.data?.message });
        }
        let frmdata1 = new FormData();
        frmdata1.append("oper", "prices");
        frmdata1.append("userid", context.userid);
        frmdata1.append("token", context.token);
        const res1 = await axios.post(API + "hotel.php", frmdata1);
        if (res1.data?.success) {
          const info = JSON.parse(res1.data.data);
          setPrices(info);
        } else {
          ToastMessage({ type: "error", message: res1.data?.message });
        }
        setLoading(false);
      } catch (error) {
        console.log(error);
        setLoading(false);
        ToastMessage({ type: "error", message: "Error" });
      }
    })();
  }, []);

  return (
    <div className="absolute top-0 left-0 w-full h-full">
      {loading && <Loading />}
      <div className="w-3/4 mx-auto bg-gray-100 mt-48 rounded-lg border min-h-96 shadow p-7">
        <div className="w-full flex gap-4">
          <div
            className={
              selected.category === "A"
                ? `w-32 text-center h-16 bg-white rounded pt-5 cursor-pointer border-blue-500 border-2`
                : `w-32 text-center h-16 bg-white rounded pt-5 cursor-pointer`
            }
            onClick={() => {
              setSelected({
                ...selected,
                hotel: "",
                roomType: "",
                category: "A",
              });
            }}
          >
            A Category
          </div>
          <div
            className={
              selected.category === "B"
                ? `w-32 text-center h-16 bg-white rounded pt-5 cursor-pointer border-blue-500 border-2`
                : `w-32 text-center h-16 bg-white rounded pt-5 cursor-pointer`
            }
            onClick={() => {
              setSelected({
                ...selected,
                hotel: "",
                roomType: "",
                category: "B",
              });
            }}
          >
            B Category
          </div>
          <div
            className={
              selected.category === "C"
                ? `w-32 text-center h-16 bg-white rounded pt-5 cursor-pointer border-blue-500 border-2`
                : `w-32 text-center h-16 bg-white rounded pt-5 cursor-pointer`
            }
            onClick={() => {
              setSelected({
                ...selected,
                hotel: "",
                roomType: "",
                category: "C",
              });
            }}
          >
            C Category
          </div>
        </div>
        <div className="w-full flex gap-4 mt-4">
          {hotels
            ?.filter((h) => h.category === selected.category)
            ?.map((h, i) => {
              return (
                <div
                  key={i}
                  className={
                    h.hid === selected.hotel
                      ? `w-52 text-center h-48 bg-white rounded cursor-pointer border-blue-500 border-2`
                      : `w-52 text-center h-48 bg-white rounded cursor-pointer`
                  }
                  onClick={() => {
                    setSelected({ ...selected, roomType: "", hotel: h.hid });
                  }}
                >
                  <div className="w-full h-28 overflow-hidden align-middle">
                    <img src={h.img} alt={h.hotelNa} width={"100%"} />
                  </div>
                  <div className="w-full h-3 flex justify-center mt-1">
                    {_.times(h.star, (e) => {
                      return (
                        <img key={e} src={"/star.png"} width={12} height={12} />
                      );
                    })}
                  </div>
                  <div>{h.hotelNa}</div>
                  <a
                    href={h.weblink}
                    className="text-xs text-blue-400"
                    target="_blank"
                  >
                    Visit website
                  </a>
                </div>
              );
            })}
        </div>
        {selected.hotel !== "" && (
          <div className="w-full flex gap-4 mt-4">
            <div
              className={
                selected.roomType === "Single"
                  ? `w-40 text-center h-16 bg-white rounded pt-5 cursor-pointer border-blue-500 border-2`
                  : `w-40 text-center h-16 bg-white rounded pt-5 cursor-pointer`
              }
              onClick={() => {
                setSelected({ ...selected, roomType: "Single" });
              }}
            >
              Single room
            </div>
            <div
              className={
                selected.roomType === "Twin"
                  ? `w-40 text-center h-16 bg-white rounded pt-2 cursor-pointer border-blue-500 border-2`
                  : `w-40 text-center h-16 bg-white rounded pt-2 cursor-pointer`
              }
              onClick={() => {
                setSelected({ ...selected, roomType: "Twin" });
              }}
            >
              Twin room <br />
              /2 people in 2 beds/
            </div>
            <div
              className={
                selected.roomType === "Double"
                  ? `w-40 text-center h-16 bg-white rounded pt-2 cursor-pointer border-blue-500 border-2`
                  : `w-40 text-center h-16 bg-white rounded pt-2 cursor-pointer`
              }
              onClick={() => {
                setSelected({ ...selected, roomType: "Double" });
              }}
            >
              Double room <br />
              /2 people in 1 beds/
            </div>
            <div
              className={
                selected.roomType === "Triple"
                  ? `w-40 text-center h-16 bg-white rounded pt-2 cursor-pointer border-blue-500 border-2`
                  : `w-40 text-center h-16 bg-white rounded pt-2 cursor-pointer`
              }
              onClick={() => {
                setSelected({ ...selected, roomType: "Triple" });
              }}
            >
              Triple room <br />
              /1 twin beds and extra/
            </div>
          </div>
        )}
        {selected.roomType !== "" && (
          <div className="w-full flex gap-4 mt-4">
            <div>
              Number of rooms :
              <input
                type="number"
                required
                className="border px-2 py-1 rounded w-20 ml-1"
                value={selected.roomNumber ?? 1}
                onChange={(e) => {
                  setSelected({
                    ...selected,
                    roomNumber: parseInt(e.target.value),
                  });
                }}
              />
            </div>
            <div>
              Check In :
              <input
                type="date"
                required
                className="border px-2 py-1 rounded w-36 ml-1"
                value={selected.checkIn}
                onChange={(e) => {
                  setSelected({
                    ...selected,
                    checkIn: e.target.value,
                  });
                }}
              />
            </div>
            <div>
              Check Out :
              <input
                type="date"
                required
                className="border px-2 py-1 rounded w-36 ml-1"
                value={selected.checkOut}
                onChange={(e) => {
                  setSelected({
                    ...selected,
                    checkOut: e.target.value,
                  });
                }}
              />
            </div>
            <div>
              Total Price :
              <input
                type="text"
                required
                readOnly
                className="border px-2 py-1 rounded w-36 ml-1 text-right"
                value={
                  (
                    Math.floor(
                      (new Date(selected.checkOut).getTime() -
                        new Date(selected.checkIn).getTime()) /
                        (1000 * 60 * 60 * 24)
                    ) *
                    prices.filter(
                      (p) =>
                        p.hotelId === selected.hotel &&
                        p.roomType === selected.roomType
                    )[0].price *
                    selected.roomNumber
                  ).toLocaleString() + " USD"
                }
              />
            </div>
          </div>
        )}
        <div className="w-full flex gap-4 mt-4">
          <div className=" w-36">
            <input
              type="button"
              value="Book"
              onClick={() => {
                addBooking();
              }}
              className="MyBgColorSky rounded py-2 w-full cursor-pointer MyWhite"
            />
          </div>
          <div className=" w-36">
            <input
              type="button"
              onClick={() => {
                hide(false);
              }}
              value="Cancel"
              className="MyBgColorRed rounded py-2 w-full cursor-pointer MyWhite"
            />
          </div>
        </div>
      </div>
    </div>
  );
}
