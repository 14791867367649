import { useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import UserContext from "../../utils/context";

export default function RegMainMenu() {
  const context = useContext(UserContext);
  const route = useNavigate();

  return (
    <div className="w-full h-14 MyBgColorPink grid grid-cols-5">
      <div className="col-span-4 flex">
        <div className="py-3">
          <Link
            className="text-white text-xl px-5"
            to={"/registration/?page=profile"}
          >
            My Profile
          </Link>
        </div>
        <div className="py-3">
          <Link
            className="text-white text-xl px-5"
            to={"/registration/?page=booking"}
          >
            My Bookings
          </Link>
        </div>
        <div className="py-3">
          <Link
            className="text-white text-xl px-5"
            to={"/registration/?page=event"}
          >
            Registration
          </Link>
        </div>
        <div className="py-3">
          <Link
            className="text-white text-xl px-5"
            to={"/registration/?page=invoice"}
          >
            Invoice
          </Link>
        </div>
        {context.admin === 1 && (
          <div className="py-3">
            <Link
              className="text-white text-xl px-5"
              to={"/registration/?page=dashboard"}
            >
              Dashboard
            </Link>
          </div>
        )}
        {context.admin === 1 && (
          <div className="py-3">
            <Link
              className="text-white text-xl px-5"
              to={"/registration/?page=setting"}
            >
              Setting
            </Link>
          </div>
        )}
      </div>
      <div className="text-right">
        <div className="py-3">
          <button
            className="text-white text-xl px-5"
            onClick={() => {
              context.setToken("");
              context.setAdmin("");
              route("/");
            }}
          >
            Log Out
          </button>
        </div>
      </div>
    </div>
  );
}
