import SelectEvent from "./eventreg/select-event";
import MainMenu from "./menu";
import { useContext, useEffect } from "react";
import UserContext from "../utils/context";
import { useNavigate } from "react-router-dom";

export default function EventRegPage() {
  const context = useContext(UserContext);
  const route = useNavigate();

  useEffect(() => {
    if (context.admin !== "1") {
      route("/login");
    }
  }, []);

  return (
    <div className="w-full">
      <MainMenu />
      <SelectEvent />
    </div>
  );
}
