import React from "react";
import BookingHotels from "./BookingHotels";
import BookingFoods from "./BookingFoods";
import { Link } from "react-router-dom";

export default function Bookings() {
  return (
    <div className="w-full px-7 py-2 max-h-[calc(100vh-56px)] overflow-auto">
      <BookingHotels />
      <BookingFoods />
      <div>
        <Link
          to={"/registration/?page=invoice"}
          className="bg-blue-500 border-blue-400 px-4 py-2 rounded-sm text-white"
        >
          Print Invoice
        </Link>
      </div>
    </div>
  );
}
