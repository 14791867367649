import React, { useContext, useEffect, useState } from "react";
import UserContext from "../../utils/context";
import Loading from "../loading";
import { API } from "../../utils/config";
import ToastMessage from "../toast";
import axios from "axios";
import moment from "moment";
import {
  Page,
  Text,
  View,
  Image,
  Document,
  StyleSheet,
  PDFViewer,
} from "@react-pdf/renderer";

export default function Invoice() {
  const context = useContext(UserContext);
  const [loading, setLoading] = useState(false);
  const [hotel, setHotel] = useState([]);
  const [food, setFood] = useState([]);
  const [deleg, setDeleg] = useState(1);
  const [dt, setDt] = useState("");
  var sum = 0;

  const getDelegNum = async () => {
    try {
      setLoading(true);
      let frmdata = new FormData();
      frmdata.append("oper", "emp");
      frmdata.append("eventid", "1");
      frmdata.append("userid", context.userid);
      frmdata.append("token", context.token);
      const res = await axios.post(API + "register.php", frmdata);

      if (res.data.success) {
        const parsed = (await JSON.parse(res.data.data))
          ? await JSON.parse(res.data.data)
          : [];
        setDeleg(parsed.length);
        if (parsed.length > 0) {
          setDt(parsed[0].crtdDt);
        }
      } else {
        ToastMessage({ type: "error", message: res.data.message });
      }

      setLoading(false);
    } catch (err) {
      console.log(err);
      setLoading(false);
      ToastMessage({ type: "error", message: "Error" });
    }
  };

  const EventHotel = async () => {
    try {
      setLoading(true);
      let frmdata = new FormData();
      frmdata.append("oper", "hotel");
      frmdata.append("eventid", "1");
      frmdata.append("userid", context.userid);
      frmdata.append("token", context.token);
      const res = await axios.post(API + "register.php", frmdata);
      if (res.data.success) {
        if (JSON.parse(res.data.data)?.length > 0) {
          const parsed = JSON.parse(res.data.data);
          let tmp = [];
          await Promise.all(
            parsed?.map((e, i) => {
              tmp.push({
                num: i + 1,
                hotelNa: e.hotelNa,
                type: e.type,
                roomtype: e.roomtype,
                hotelSt: e.hotelSt,
                hotelEd: e.hotelEd,
                roomNu: e.roomNu,
                price: e.price,
                id: e.id,
              });
            })
          );
          setHotel(tmp);
        }
      } else {
        ToastMessage({ type: "error", message: res.data.message });
      }
      setLoading(false);
    } catch (err) {
      setLoading(false);
      console.log(err);
      ToastMessage({ type: "error", message: "Error" });
    }
  };

  const EventFood = async () => {
    try {
      setLoading(true);
      let frmdata = new FormData();
      frmdata.append("oper", "food");
      frmdata.append("eventid", "1");
      frmdata.append("userid", context.userid);
      frmdata.append("token", context.token);
      const res = await axios.post(API + "register.php", frmdata);
      if (res.data.success) {
        if (JSON.parse(res.data.data)?.length > 0) {
          const parsed = JSON.parse(res.data.data);
          let tmp = [];
          await Promise.all(
            parsed?.map((e, i) => {
              tmp.push({
                num: i + 1,
                foodNa: e.foodNa,
                st: e.st,
                ed: e.ed,
                foodNu: e.foodNu,
                price: e.price,
                id: e.id,
              });
            })
          );
          setFood(tmp);
        }
      } else {
        ToastMessage({ type: "error", message: res.data.message });
      }
      setLoading(false);
    } catch (err) {
      setLoading(false);
      console.log(err);
      ToastMessage({ type: "error", message: "Error" });
    }
  };

  useEffect(() => {
    (async () => {
      await getDelegNum();
      await EventHotel();
      await EventFood();
    })();
  }, []);

  return (
    <div className="w-full px-20 py-6">
      {loading && <Loading />}
      <PDFViewer width={"100%"} height={"800px"}>
        <Document>
          <Page
            size="A4"
            width="100%"
            style={{
              paddingLeft: "20px",
              paddingRight: "20px",
              paddingTop: "10px",
              paddingBottom: "10px",
              fontSize: "11px",
              fontWeight: "normal",
            }}
          >
            <View
              style={{
                width: "100%",
                flexDirection: "row",
                gap: 20,
                justifyContent: "center",
                borderBottomWidth: 1,
                borderColor: "#CCC",
              }}
            >
              <View style={{ width: "330%", alignItems: "center" }}>
                <Image src="/logo1.png" style={{ height: 110, marginTop: 5 }} />
              </View>
              <View style={{ width: "330%", alignItems: "center" }}>
                <Image src="/logo2.png" style={{ height: 130 }} />
              </View>
              <View style={{ width: "330%", alignItems: "center" }}>
                <Image src="/logo3.png" style={{ height: 140 }} />
              </View>
            </View>
            <View style={{ flexDirection: "row", marginTop: "15px" }}>
              <Text>INVOICE : </Text>
              <Text style={{ fontWeight: "bold", marginLeft: 6 }}>
                {moment(dt).format("YYYYMMDDHHmm")}
              </Text>
            </View>
            <View style={{ flexDirection: "row", marginTop: "5px" }}>
              <Text>DATE : </Text>
              <Text style={{ fontWeight: "bold", marginLeft: 6 }}>
                {moment(dt).format("YYYY-MM-DD")}
              </Text>
            </View>
            <View
              style={{
                width: "100%",
                borderWidth: 1,
                borderColor: "#CCC",
                flexDirection: "row",
                marginTop: "10px",
              }}
            >
              <View
                style={{
                  padding: "2px 5px",
                  flex: 1,
                  borderRightWidth: 1,
                  borderRightColor: "#CCC",
                  fontWeight: "extrabold",
                }}
              >
                <Text>Description</Text>
              </View>
              <View
                style={{
                  padding: "2px 5px",
                  width: 80,
                  borderRightWidth: 1,
                  borderRightColor: "#CCC",
                  textAlign: "center",
                }}
              >
                <Text>Unit</Text>
              </View>
              <View
                style={{
                  padding: "2px 5px",
                  width: 50,
                  borderRightWidth: 1,
                  borderRightColor: "#CCC",
                  textAlign: "center",
                }}
              >
                <Text>Q'ty</Text>
              </View>
              <View
                style={{ padding: "2px 5px", width: 80, textAlign: "center" }}
              >
                <Text>Total</Text>
              </View>
            </View>
            <View
              style={{
                width: "100%",
                borderLeftWidth: 1,
                borderBottomWidth: 1,
                borderRightWidth: 1,
                borderColor: "#CCC",
                flexDirection: "row",
              }}
            >
              <View
                style={{
                  padding: "2px 10px",
                  flex: 1,
                  borderRightWidth: 1,
                  borderRightColor: "#CCC",
                }}
              >
                <Text>Wristband fee</Text>
              </View>
              <View
                style={{
                  padding: "2px 5px",
                  width: 80,
                  borderRightWidth: 1,
                  borderRightColor: "#CCC",
                  textAlign: "center",
                }}
              >
                <Text>Person</Text>
              </View>
              <View
                style={{
                  padding: "2px 5px",
                  width: 50,
                  borderRightWidth: 1,
                  borderRightColor: "#CCC",
                  textAlign: "center",
                }}
              >
                <Text>{deleg}</Text>
              </View>
              <View
                style={{ padding: "2px 5px", width: 80, textAlign: "center" }}
              >
                <Text>$ {deleg * 250}</Text>
              </View>
            </View>
            {hotel?.map((h, i) => {
              sum += h.price;
              return (
                <View
                  key={i}
                  style={{
                    width: "100%",
                    borderLeftWidth: 1,
                    borderBottomWidth: 1,
                    borderRightWidth: 1,
                    borderColor: "#CCC",
                    flexDirection: "row",
                  }}
                >
                  <View
                    style={{
                      padding: "2px 10px",
                      flex: 1,
                      borderRightWidth: 1,
                      borderRightColor: "#CCC",
                    }}
                  >
                    <Text>
                      {h.hotelNa} - {h.roomtype} <br />/{h.hotelSt}-{h.hotelEd}/
                    </Text>
                  </View>
                  <View
                    style={{
                      padding: "2px 5px",
                      width: 80,
                      borderRightWidth: 1,
                      borderRightColor: "#CCC",
                      textAlign: "center",
                    }}
                  >
                    <Text>Room</Text>
                  </View>
                  <View
                    style={{
                      padding: "2px 5px",
                      width: 50,
                      borderRightWidth: 1,
                      borderRightColor: "#CCC",
                      textAlign: "center",
                    }}
                  >
                    <Text>{h.roomNu}</Text>
                  </View>
                  <View
                    style={{
                      padding: "2px 5px",
                      width: 80,
                      textAlign: "center",
                    }}
                  >
                    <Text>$ {h.price}</Text>
                  </View>
                </View>
              );
            })}
            {food?.map((h, i) => {
              sum += h.price;
              return (
                <View
                  key={i}
                  style={{
                    width: "100%",
                    borderLeftWidth: 1,
                    borderBottomWidth: 1,
                    borderRightWidth: 1,
                    borderColor: "#CCC",
                    flexDirection: "row",
                  }}
                >
                  <View
                    style={{
                      padding: "2px 10px",
                      flex: 1,
                      borderRightWidth: 1,
                      borderRightColor: "#CCC",
                    }}
                  >
                    <Text>
                      {h.foodNa} /{h.st}-{h.ed}/
                    </Text>
                  </View>
                  <View
                    style={{
                      padding: "2px 5px",
                      width: 80,
                      borderRightWidth: 1,
                      borderRightColor: "#CCC",
                      textAlign: "center",
                    }}
                  >
                    <Text>Person</Text>
                  </View>
                  <View
                    style={{
                      padding: "2px 5px",
                      width: 50,
                      borderRightWidth: 1,
                      borderRightColor: "#CCC",
                      textAlign: "center",
                    }}
                  >
                    <Text>{h.foodNu}</Text>
                  </View>
                  <View
                    style={{
                      padding: "2px 5px",
                      width: 80,
                      textAlign: "center",
                    }}
                  >
                    <Text>$ {h.price}</Text>
                  </View>
                </View>
              );
            })}
            <View
              style={{
                width: "100%",
                borderLeftWidth: 1,
                borderBottomWidth: 1,
                borderRightWidth: 1,
                borderColor: "#CCC",
                flexDirection: "row",
              }}
            >
              <View
                style={{
                  padding: "2px 10px",
                  flex: 1,
                  borderRightWidth: 1,
                  borderRightColor: "#CCC",
                  textAlign: "right",
                }}
              >
                <Text>Subtotal</Text>
              </View>
              <View
                style={{
                  padding: "2px 5px",
                  width: 80,
                  textAlign: "center",
                }}
              >
                <Text>$ {sum}</Text>
              </View>
            </View>

            <View style={{ marginTop: "15px" }}>
              <Text>Bank Details</Text>
              <Text style={{ marginLeft: "20px", marginTop: "8px" }}>
                Bank name: Trade and Development Bank of Mongolia
              </Text>
              <Text style={{ marginLeft: "20px", marginTop: "4px" }}>
                Beneficiary: Mongolian Gymnastic Federation
              </Text>
              <Text style={{ marginLeft: "20px", marginTop: "4px" }}>
                Account No: 411022001
              </Text>
              <Text style={{ marginLeft: "20px", marginTop: "4px" }}>
                Swift Code: TDBMMNUB
              </Text>
              <Text style={{ marginLeft: "20px", marginTop: "4px" }}>
                Bank Address: TDBM Building, Peace Avenue 19, Sukhbaatar
                district, 1-Khoroo
              </Text>
            </View>
          </Page>
        </Document>
      </PDFViewer>
    </div>
  );
}
