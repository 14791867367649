import MainMenu from "./menu";
import UserContext from "../utils/context";
import { useNavigate } from "react-router-dom";
import { useContext, useEffect } from "react";

export default function DashboardPage(props) {
  const context = useContext(UserContext);
  const route = useNavigate();

  useEffect(() => {
    if (context.admin !== "1") {
      route("/login");
    }
  }, []);

  return (
    <div className="w-full">
      <MainMenu />
    </div>
  );
}
