import PropTypes from "prop-types";
import {
  FaBug,
  FaCheck,
  FaExclamationCircle,
  FaExclamationTriangle,
  FaInfo,
} from "react-icons/fa";
import { toast } from "react-toastify";

export const displayIcon = (type) => {
  switch (type) {
    case "success":
      return <FaCheck />;
    case "info":
      return <FaInfo />;
    case "error":
      return <FaExclamationCircle />;
    case "warning":
      return <FaExclamationTriangle />;
    default:
      return <FaBug />;
  }
};

const ToastMessage = ({ type, message }) => {
  type === "error"
    ? toast[type](
        <div
          style={{
            display: "flex",
          }}
        >
          <div
            style={{
              flexGrow: 1,
              fontSize: 12,
              padding: "3px 3px",
            }}
          >
            {message}
          </div>
        </div>
      )
    : toast["success"](
        <div
          style={{
            display: "flex",
          }}
        >
          <div
            style={{
              flexGrow: 1,
              fontSize: 12,
              padding: "3px 3px",
            }}
          >
            {message}
          </div>
        </div>
      );
};

ToastMessage.propTypes = {
  message: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
};

ToastMessage.dismiss = toast.dismiss;

export default ToastMessage;
