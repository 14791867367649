import React from "react";
import FoodList from "../food/food-list";
import HotelList from "../hotel/hotel-list";

export default function Settings() {
  return (
    <div className="w-full px-7 py-2 max-h-[calc(100vh-56px)] overflow-auto">
      Settings
      <FoodList />
      <HotelList />
    </div>
  );
}
