import axios from "axios";
import { useContext, useEffect, useState } from "react";
import { API } from "../../utils/config";
import UserContext from "../../utils/context";
import Loading from "../loading";
import ToastMessage from "../toast";

export default function HotelPriceEdit({ record, isload, setIsload, setShow }) {
  const [roomType, setRoomType] = useState("");
  const [price, setPrice] = useState(0);
  const context = useContext(UserContext);
  const [loading, setLoading] = useState(false);

  const SaveHotel = async (e) => {
    e.preventDefault();
    if (roomType === "") {
      ToastMessage({ type: "error", message: "Insert Room Type" });
      return;
    }
    if (price === 0) {
      ToastMessage({ type: "error", message: "Insert Price" });
      return;
    }

    setLoading(true);
    let formdata = new FormData();
    formdata.append("roomType", roomType);
    formdata.append("price", price);
    formdata.append("id", record.id);
    formdata.append("token", context.token);
    formdata.append("oper", "priceupdate");
    try {
      const res = await axios.post(API + "hotel.php", formdata);
      if (res.data.success) {
        setIsload(isload + 1);
        setShow(false);
      } else {
        ToastMessage({ type: "error", message: res.data.message });
      }
      setLoading(false);
    } catch (err) {
      console.log(err);
      setLoading(false);
      ToastMessage({ type: "error", message: "Error" });
    }
  };

  useEffect(() => {
    setRoomType(record.roomType);
    setPrice(record.price);
  }, [record]);

  return (
    <div className="bg-gray-50 w-full h-full absolute top-0 left-0 pt-40 bg-opacity-90">
      <div className="bg-white max-w-5xl mx-auto p-10 text-2xl rounded border shadow text-black">
        <div className="w-full text-left">
          {loading && <Loading />}
          <form onSubmit={SaveHotel}>
            <div className="my-3 w-full flex">
              <div className="min-w-40 p-2">Room Type : </div>
              <input
                type="text"
                name="roomType"
                required
                value={roomType}
                onChange={(e) => {
                  setRoomType(e.target.value);
                }}
                placeholder="Room Type"
                className="border p-2 rounded w-full"
              />
            </div>
            <div className="my-3 w-full flex">
              <div className="min-w-40 p-2">Price : </div>
              <input
                type="text"
                name="price"
                required
                value={price}
                onChange={(e) => {
                  setPrice(parseInt(e.target.value));
                }}
                placeholder="Price"
                className="border p-2 rounded w-full"
              />
            </div>
            <div className="my-3 w-full flex justify-end gap-4">
              <input
                type="button"
                value="Cancel"
                onClick={() => {
                  setShow(false);
                }}
                className="bg-red-600 border border-red-700 rounded py-3 px-10 cursor-pointer"
              />
              <input
                type="submit"
                value="Update Room Type"
                className="bg-green-600 border border-green-700 rounded py-3 px-10 cursor-pointer"
              />
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
