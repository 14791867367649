import { useContext, useEffect, useState } from "react";
import ToastMessage from "../toast";
import { API } from "../../utils/config";
import axios from "axios";
import Loading from "../loading";
import UserContext from "../../utils/context";

export default function TourEdit({ isload, setIsload, setShow, tour }) {
  const [tid, setTid] = useState("");
  const [tourNa, setTourNa] = useState("");
  const [tourDs, setTourDs] = useState("");
  const context = useContext(UserContext);
  const [loading, setLoading] = useState(false);

  const UpdateTour = async (e) => {
    e.preventDefault();
    if (tourNa === "") {
      ToastMessage({ type: "error", message: "Insert Tour name" });
      return;
    }
    if (tourDs === "") {
      ToastMessage({ type: "error", message: "Insert Tour description" });
      return;
    }
    let formdata = new FormData();
    formdata.append("tid", tid);
    formdata.append("tourNa", tourNa);
    formdata.append("tourDs", tourDs);
    formdata.append("token", context.token);
    formdata.append("oper", "update");
    try {
      setLoading(true);
      const res = await axios.post(API + "tour.php", formdata);
      if (res.data.success) {
        setIsload(isload + 1);
        setShow(false);
      } else {
        ToastMessage({ type: "error", message: res.data.message });
      }
      setLoading(false);
    } catch (err) {
      console.log(err);
      setLoading(false);
      ToastMessage({ type: "error", message: "Error" });
    }
  };

  useEffect(() => {
    setTid(tour.tid);
    setTourNa(tour.tourNa);
    setTourDs(tour.tourDs);
  }, []);

  return (
    <div className="bg-gray-50 w-full h-full absolute top-0 left-0 pt-40 bg-opacity-90">
      <div className="bg-white max-w-5xl mx-auto p-10 text-2xl rounded border shadow text-black">
        <div className="w-full text-left">
          {loading && <Loading />}
          <form onSubmit={UpdateTour}>
            <div className="my-3 w-full flex">
              <div className="min-w-40 p-2">Name : </div>
              <input
                type="text"
                name="tourNa"
                value={tourNa}
                onChange={(e) => {
                  setTourNa(e.target.value);
                }}
                required
                placeholder="Food Name"
                className="border p-2 rounded w-full"
              />
            </div>
            <div className="my-3 w-full flex">
              <div className="min-w-40 p-2">Description : </div>
              <textarea
                name="tourDs"
                value={tourDs}
                onChange={(e) => {
                  setTourDs(e.target.value);
                }}
                className="border p-2 rounded w-full"
              ></textarea>
            </div>
            <div className="my-3 w-full flex justify-end gap-4">
              <input
                type="button"
                value="Cancel"
                onClick={() => {
                  setShow(false);
                }}
                className="bg-red-600 border border-red-700 rounded py-3 px-10 cursor-pointer"
              />
              <input
                type="submit"
                value="Update Tour"
                className="bg-green-600 border border-green-700 rounded py-3 px-10 cursor-pointer"
              />
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
