import React, { useState } from "react";

const UserContext = React.createContext();

export const UserStore = (props) => {
  const [userid, setUserid] = useState("");
  const [token, setToken] = useState("");
  const [email, setEmail] = useState("");
  const [country, setCountry] = useState("");
  const [expire, setExpire] = useState("");
  const [admin, setAdmin] = useState("");

  return (
    <UserContext.Provider
      value={{
        userid,
        setUserid,
        token,
        setToken,
        email,
        setEmail,
        country,
        setCountry,
        expire,
        setExpire,
        admin,
        setAdmin,
      }}
    >
      {props.children}
    </UserContext.Provider>
  );
};

export default UserContext;
