import axios from "axios";
import { useContext, useState } from "react";
import { API } from "../../utils/config";
import UserContext from "../../utils/context";
import Loading from "../loading";
import ToastMessage from "../toast";

export default function HotelAdd({ isload, setIsload, setShow }) {
  const [hotelNa, setHotelNa] = useState("");
  const [category, setCategory] = useState("A");
  const [star, setStar] = useState(5);
  const [img, setImg] = useState("");
  const [weblink, setWeblink] = useState("");
  const context = useContext(UserContext);
  const [loading, setLoading] = useState(false);

  const SaveHotel = async (e) => {
    e.preventDefault();
    if (hotelNa === "") {
      ToastMessage({ type: "error", message: "Insert Hotel name" });
      return;
    }
    if (category === "") {
      ToastMessage({ type: "error", message: "Insert Category" });
      return;
    }
    if (img === "") {
      ToastMessage({ type: "error", message: "Insert Image URL" });
      return;
    }

    setLoading(true);
    let formdata = new FormData();
    formdata.append("hotelNa", hotelNa);
    formdata.append("category", category);
    formdata.append("star", star);
    formdata.append("img", img);
    formdata.append("weblink", weblink);
    formdata.append("token", context.token);
    formdata.append("oper", "add");
    try {
      const res = await axios.post(API + "hotel.php", formdata);
      if (res.data.success) {
        setIsload(isload + 1);
        setShow(false);
      } else {
        ToastMessage({ type: "error", message: res.data.message });
      }
      setLoading(false);
    } catch (err) {
      console.log(err);
      setLoading(false);
      ToastMessage({ type: "error", message: "Error" });
    }
  };

  return (
    <div className="bg-gray-50 w-full h-full absolute top-0 left-0 pt-40 bg-opacity-90">
      <div className="bg-white max-w-5xl mx-auto p-10 text-2xl rounded border shadow text-black">
        <div className="w-full text-left">
          {loading && <Loading />}
          <form onSubmit={SaveHotel}>
            <div className="my-3 w-full flex">
              <div className="min-w-40 p-2">Name : </div>
              <input
                type="text"
                name="hotelNa"
                required
                value={hotelNa}
                onChange={(e) => {
                  setHotelNa(e.target.value);
                }}
                placeholder="Hotel Name"
                className="border p-2 rounded w-full"
              />
            </div>
            <div className="my-3 w-full flex">
              <div className="min-w-40 p-2">Category : </div>
              <input
                type="text"
                name="categoty"
                required
                value={category}
                onChange={(e) => {
                  setCategory(e.target.value);
                }}
                placeholder="Categoty"
                className="border p-2 rounded w-full"
              />
            </div>
            <div className="my-3 w-full flex">
              <div className="min-w-40 p-2">Star : </div>
              <input
                type="number"
                name="star"
                required
                value={star}
                onChange={(e) => {
                  setStar(e.target.value);
                }}
                defaultValue={5}
                className="border p-2 rounded w-full"
              />
            </div>
            <div className="my-3 w-full flex">
              <div className="min-w-40 p-2">Image URL : </div>
              <input
                type="text"
                name="img"
                required
                value={img}
                onChange={(e) => {
                  setImg(e.target.value);
                }}
                placeholder="Image URL"
                className="border p-2 rounded w-full"
              />
            </div>
            <div className="my-3 w-full flex">
              <div className="min-w-40 p-2">Web Site : </div>
              <input
                type="text"
                name="weblink"
                required
                value={weblink}
                onChange={(e) => {
                  setWeblink(e.target.value);
                }}
                placeholder="Web Site"
                className="border p-2 rounded w-full"
              />
            </div>
            <div className="my-3 w-full flex justify-end gap-4">
              <input
                type="button"
                value="Cancel"
                onClick={() => {
                  setShow(false);
                }}
                className="bg-red-600 border border-red-700 rounded py-3 px-10 cursor-pointer"
              />
              <input
                type="submit"
                value="Add Hotel"
                className="bg-green-600 border border-green-700 rounded py-3 px-10 cursor-pointer"
              />
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
