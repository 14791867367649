import { API } from "../../utils/config";
import axios from "axios";
import { useContext, useEffect, useState } from "react";
import * as XLSX from "xlsx";
import ToastMessage from "../toast";
import Loading from "../loading";
import UserContext from "../../utils/context";

export default function EventRegEmp({ eventid }) {
  const [data, setData] = useState([]);
  const context = useContext(UserContext);
  const [loading, setLoading] = useState(false);

  const download = () => {
    const xl = data?.map((d, i) => ({
      num: i + 1,
      FirstName: d.frstNa,
      LastName: d.lastNa,
      PassportNu: d.passNu,
      Gender: d.gender,
      BirthDay: d.brthDa,
      PhoneNumber: d.phonNu,
      Nationality: d.nation,
      ArriveDt: d.arrvDt,
      ArriveTi: d.arrvTi,
      ArriveFl: d.ArriveFl,
      DepartureDt: d.deptDt,
      DepartureTi: d.deptTi,
      DepartureFl: d.deptFl,
      Registered: d.crtdDt,
    }));
    const worksheet = XLSX.utils.json_to_sheet(xl);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Delegations");
    XLSX.writeFile(workbook, "Delegations.xlsx");
  };

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        let data = new FormData();
        data.append("oper", "emp");
        data.append("evntId", eventid);
        data.append("token", context.token);
        const res = await axios.post(API + "eventreg.php", data);
        if (res.data.success) {
          setData(JSON.parse(res.data.data));
        } else {
          ToastMessage({ type: "error", message: res.data.message });
        }
        setLoading(false);
      } catch (err) {
        console.log(err);
        setLoading(false);
        ToastMessage({ type: "error", message: "Error" });
      }
    })();
  }, [eventid]);

  return (
    <div className="w-full">
      {loading && <Loading />}
      <h1 className="text-3xl my-2 text-red-400">Registered delegations</h1>
      <div className="w-full text-right">
        <button
          className="bg-green-600 border border-green-700 rounded py-1 px-4 text-sm text-white cursor-pointer"
          onClick={download}
        >
          Export data
        </button>
      </div>
      <table border={1} width="100%">
        <thead>
          <tr>
            <th align="left">#</th>
            <th align="left">First Name</th>
            <th align="left">Last Name</th>
            <th align="left">Gender</th>
            <th align="left">Passport №</th>
            <th align="left">Birth Date</th>
            <th align="left">Phone №</th>
            <th align="left">Nationality</th>
            <th align="left">Passport Photo</th>
            <th align="left">Arrive Dt</th>
            <th align="left">Arrive Ti</th>
            <th align="left">Arrive Fl</th>
            <th align="left">Departure Dt</th>
            <th align="left">Departure Ti</th>
            <th align="left">Departure Fl</th>
            <th align="left">E-Mail</th>
            <th align="left">Registered</th>
          </tr>
        </thead>
        <tbody>
          {data?.map((d, i) => {
            return (
              <tr key={i} className="text-sm border-b">
                <td className="p-1 text-left">{i + 1}</td>
                <td className="p-1 text-left">{d.frstNa}</td>
                <td className="p-1 text-left">{d.lastNa}</td>
                <td className="p-1 text-left">{d.gender}</td>
                <td className="p-1 text-left">{d.passNu}</td>
                <td className="p-1 text-left">{d.brthDa}</td>
                <td className="p-1 text-left">{d.phonNu}</td>
                <td className="p-1 text-left">{d.nation}</td>
                <td className="p-1 text-left">
                  <img src={d.passIm} className="w-20 h-20 cursor-pointer" />
                </td>
                <td className="p-1 text-left">{d.arrvDt}</td>
                <td className="p-1 text-left">{d.arrvTi}</td>
                <td className="p-1 text-left">{d.arrvFl}</td>
                <td className="p-1 text-left">{d.deptDt}</td>
                <td className="p-1 text-left">{d.deptTi}</td>
                <td className="p-1 text-left">{d.deptFl}</td>
                <td className="p-1 text-left">{d.email}</td>
                <td className="p-1 text-left">{d.crtdDt}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
}
