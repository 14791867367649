import React, { useContext } from "react";
import { useState } from "react";
import ToastMessage from "./toast";
import axios from "axios";
import { API } from "../utils/config";
import Loading from "./loading";
import UserContext from "../utils/context";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";

export default function HomeScreen() {
  const [mail, setMail] = useState("");
  const [password, setPassword] = useState("");
  const route = useNavigate();

  const context = useContext(UserContext);
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      let data = new FormData();
      data.append("oper", "login");
      data.append("uname", mail);
      data.append("upass", password);
      const res = await axios.post(API + "login.php", data);
      if (res.data?.success) {
        context?.setUserid(JSON.parse(res.data.data).userid);
        context?.setToken(JSON.parse(res.data.data).token);
        context?.setEmail(JSON.parse(res.data.data).email);
        context?.setCountry(JSON.parse(res.data.data).country);
        context?.setAdmin(JSON.parse(res.data.data).admin);
        context?.setExpire(JSON.parse(res.data.data).expire);
        route("/registration/?page=profile");
      } else {
        ToastMessage({ type: "error", message: res.data?.message });
      }
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
      ToastMessage({ type: "error", message: "Error" });
    }
  };

  return (
    <div className="w-full grid grid-cols-4 min-w-[1024px]">
      <div>
        {loading && <Loading />}
        <div className="w-full">
          <img src="/logo.jpeg" alt="" width={300} className="mx-auto" />
        </div>
        <div className="w-full px-7">
          <form onSubmit={handleSubmit}>
            <div className="my-3 w-full flex">
              <div className="min-w-28 p-2 mytextb">E-Mail : </div>
              <input
                type="email"
                name="email"
                required
                value={mail}
                onChange={(e) => {
                  setMail(e.target.value);
                }}
                placeholder="E-Mail address"
                className="border px-2 py-0 rounded w-full text-gray-900"
              />
            </div>
            <div className="my-3 w-full flex">
              <div className="min-w-28 p-2 mytextb">Password : </div>
              <input
                type="password"
                name="passd"
                value={password}
                onChange={(e) => {
                  setPassword(e.target.value);
                }}
                required
                placeholder="Password"
                className="border px-2 py-0 rounded w-full text-gray-900"
              />
            </div>
            <div className="my-3 w-full flex">
              <input
                type="submit"
                value="Sign In"
                className="MyBgColorSky border border-sky-400 rounded py-2 w-full cursor-pointer MyWhite"
              />
            </div>
            <div className="my-3 w-full grid grid-cols-2">
              <div className="text-center">
                <Link to={"/signup"}>Don’t have an account? </Link>
              </div>
              <div className="text-center">
                <Link to={"/forgetpass"}>Forger Password</Link>
              </div>
            </div>
          </form>
        </div>
      </div>
      <div className=" col-span-3 bg-[url('about.jpg')] bg-center bg-no-repeat bg-cover h-[100vh]"></div>
    </div>
  );
}
