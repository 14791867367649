import { useContext } from "react";
import UserContext from "../utils/context";
import { Link, useNavigate } from "react-router-dom";

export default function MainMenu() {
  const context = useContext(UserContext);
  const route = useNavigate();

  return (
    <div className="w-full h-14 bg-gray-400 grid grid-cols-5">
      <div className="col-span-4 flex">
        <div className="py-3">
          <Link className="text-white text-xl px-5" to={"/dashboard/event"}>
            Event
          </Link>
        </div>
        <div className="py-3">
          <Link className="text-white text-xl px-5" to={"/dashboard/hotel"}>
            Hotel
          </Link>
        </div>
        <div className="py-3">
          <Link className="text-white text-xl px-5" to={"/dashboard/food"}>
            Food
          </Link>
        </div>
        <div className="py-3">
          <Link className="text-white text-xl px-5" to={"/dashboard/tour"}>
            Tour
          </Link>
        </div>
        <div className="py-3">
          <Link className="text-white text-xl px-5" to={"/dashboard/eventreg"}>
            Event registration
          </Link>
        </div>
      </div>
      <div className="text-right">
        <div className="py-3">
          <button
            className="text-white text-xl px-5"
            onClick={() => {
              context.setToken("");
              context.setAdmin("");
              route("/");
            }}
          >
            Log Out
          </button>
        </div>
      </div>
    </div>
  );
}
