import "./App.css";
import { Route, Routes } from "react-router-dom";
import HomeScreen from "./components/homeScreen";

import LoginPage from "./components/loginScreen";
import DashboardPage from "./components/dashboardScreen";
import EventPage from "./components/eventScreen";
import HotelPage from "./components/hotelScreen";
import FoodPage from "./components/foodScreen";
import TourPage from "./components/tourScreen";
import EventRegPage from "./components/eventregScreen";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import SignUpScreen from "./components/SignUp";
import RegistrationHome from "./components/registration/registrationHome";

function App() {
  return (
    <div className="w-full h-screen bg-white">
      <ToastContainer
        position="top-right"
        autoClose={2500}
        hideProgressBar={false}
        newestOnTop={false}
        draggable={false}
        closeOnClick
        pauseOnHover
      />
      <Routes>
        <Route path="/" Component={HomeScreen} exact />
        <Route path="/signup" Component={SignUpScreen} exact />
        <Route path="/forgetpass" Component={SignUpScreen} exact />
        <Route path="/registration" Component={RegistrationHome} exact />

        <Route path="/login" Component={LoginPage} />
        <Route path="/dashboard" Component={DashboardPage} exact />
        <Route path="/dashboard/event" Component={EventPage} exact />
        <Route path="/dashboard/hotel" Component={HotelPage} exact />
        <Route path="/dashboard/food" Component={FoodPage} exact />
        <Route path="/dashboard/tour" Component={TourPage} exact />
        <Route path="/dashboard/eventreg" Component={EventRegPage} exact />
      </Routes>
    </div>
  );
}

export default App;
