import React, { Fragment, useContext, useEffect, useState } from "react";
import { toBase64 } from "../../utils";
import { SlUserFollow, SlUserUnfollow } from "react-icons/sl";
import ToastMessage from "../toast";
import axios from "axios";
import { API } from "../../utils/config";
import Loading from "../loading";
import UserContext from "../../utils/context";

export default function Event() {
  const context = useContext(UserContext);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([
    {
      frstNa: "",
      lastNa: "",
      gender: "Male",
      passNu: "",
      brthDa: "",
      phonNu: "",
      passIm: "",
      position: "Gymnast",
      arrvDt: "",
      arrvTi: "",
      arrvFl: "",
      deptDt: "",
      deptTi: "",
      deptFl: "",
      isnew: 1,
      id: 0,
      num: 1,
    },
  ]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      await Promise.all(
        data?.map(async (e) => {
          console.log(e);
          let frmdata = new FormData();
          frmdata.append("oper", "emp");
          frmdata.append("eventid", "1");
          frmdata.append("frstNa", e.frstNa);
          frmdata.append("lastNa", e.lastNa);
          frmdata.append("passNu", e.passNu);
          frmdata.append("gender", e.gender);
          frmdata.append("brthDa", e.brthDa);
          frmdata.append("phonNu", e.phonNu);
          frmdata.append("passIm", e.passIm);
          frmdata.append("position", e.position);
          frmdata.append("arrvDt", e.arrvDt);
          frmdata.append("arrvTi", e.arrvTi);
          frmdata.append("arrvFl", e.arrvFl);
          frmdata.append("deptDt", e.deptDt);
          frmdata.append("deptTi", e.deptTi);
          frmdata.append("deptFl", e.deptFl);
          frmdata.append("nation", context.country);
          frmdata.append("userid", context.userid);
          frmdata.append("isnew", e.isnew);
          frmdata.append("id", e.id);
          frmdata.append("token", context.token);
          const res = await axios.post(API + "save.php", frmdata);
          console.log(res.data);
          if (!res.data.success) {
            ToastMessage({ type: "error", message: res.data.message });
          }
        })
      );
      ToastMessage({ type: "success", message: "Successfully registered" });
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
      ToastMessage({ type: "error", message: "Error" });
    }
  };

  const delEmp = async (num, id) => {
    try {
      if (window.confirm("Are you sure?") === true) {
        setLoading(true);
        let frmdata = new FormData();
        frmdata.append("oper", "empdel");
        frmdata.append("eventid", "1");
        frmdata.append("id", id);
        frmdata.append("userid", context.userid);
        frmdata.append("token", context.token);
        const res = await axios.post(API + "register.php", frmdata);
        if (res.data.success) {
          setData(data.filter((dl) => dl.num !== num));
          ToastMessage({ type: "success", message: res.data.message });
          setLoading(false);
        } else {
          ToastMessage({ type: "error", message: res.data.message });
          setLoading(false);
        }
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
      ToastMessage({ type: "error", message: "Error" });
    }
  };

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        let frmdata = new FormData();
        frmdata.append("oper", "emp");
        frmdata.append("eventid", "1");
        frmdata.append("userid", context.userid);
        frmdata.append("token", context.token);
        const res = await axios.post(API + "register.php", frmdata);

        if (res.data.success) {
          const parsed = (await JSON.parse(res.data.data))
            ? await JSON.parse(res.data.data)
            : [];
          let tmp = [];
          await Promise.all(
            parsed?.map((e, i) => {
              tmp.push({
                num: i + 1,
                frstNa: e.frstNa,
                lastNa: e.lastNa,
                gender: e.gender,
                passNu: e.passNu,
                brthDa: e.brthDa,
                phonNu: e.phonNu,
                passIm: e.passIm,
                isnew: 0,
                id: e.id,
                position: e.position,
                arrvDt: e.arrvDt,
                arrvTi: e.arrvTi,
                arrvFl: e.arrvFl,
                deptDt: e.deptDt,
                deptTi: e.deptTi,
                deptFl: e.deptFl,
              });
            })
          );
          setData(tmp);
        } else {
          ToastMessage({ type: "error", message: res.data.message });
        }

        setLoading(false);
      } catch (err) {
        console.log(err);
        setLoading(false);
        ToastMessage({ type: "error", message: "Error" });
      }
    })();
  }, []);
  return (
    <div className="w-full px-7 py-2 max-h-[calc(100vh-56px)] overflow-auto">
      {loading && <Loading />}
      <form onSubmit={handleSubmit}>
        <table border={1} width="100%">
          <thead>
            <tr>
              <th>#</th>
              <th align="center" colSpan={2}>
                <div
                  className="flex cursor-pointer "
                  onClick={() => {
                    setData((prev) => {
                      return [
                        ...prev,
                        {
                          frstNa: "",
                          lastNa: "",
                          gender: "Male",
                          passNu: "",
                          brthDa: "",
                          phonNu: "",
                          passIm: "",
                          position: "Gymnast",
                          arrvDt: data[data.length - 1]?.arrvDt ?? "",
                          arrvTi: data[data.length - 1]?.arrvTi ?? "",
                          arrvFl: data[data.length - 1]?.arrvFl ?? "",
                          deptDt: data[data.length - 1]?.deptDt ?? "",
                          deptTi: data[data.length - 1]?.deptTi ?? "",
                          deptFl: data[data.length - 1]?.deptFl ?? "",
                          num: data.length + 1,
                          isnew: 1,
                          id: 0,
                        },
                      ];
                    });
                  }}
                >
                  <SlUserFollow className="MyColorGreen m-1" />
                  <div className="ml-1 bg-white p-1 border border-green-400 rounded-md text-xs">
                    Add participants
                  </div>
                </div>
              </th>
              <th colSpan={7}>PARTICIPANTS</th>
            </tr>
          </thead>
          <tbody>
            {data?.map((d) => {
              return (
                <Fragment key={d.num}>
                  <tr>
                    <td className="p-1" rowSpan={2}>
                      {d.num}
                    </td>
                    <td className="p-1" align="center" rowSpan={2}>
                      <SlUserUnfollow
                        className="cursor-pointer MyColorRed"
                        onClick={() => {
                          delEmp(d.num, d.id);
                        }}
                      />
                    </td>
                    <td className="p-1">
                      Position
                      <select
                        className="border px-2 py-1 rounded w-full"
                        value={d.position}
                        onChange={(e) => {
                          setData(
                            data.map((o) =>
                              o.num !== d.num
                                ? { ...o }
                                : { ...o, position: e.target.value }
                            )
                          );
                        }}
                      >
                        <option value={"Coach"}>Coach</option>
                        <option value={"Team manager"}>Team manager</option>
                        <option value={"Gymnast"}>Gymnast</option>
                        <option value={"Doctor"}>Doctor</option>
                      </select>
                    </td>
                    <td className="p-1">
                      First Name
                      <input
                        type="text"
                        required
                        placeholder="First Name"
                        className="border px-2 py-1 rounded w-full"
                        value={d.frstNa}
                        onChange={(e) => {
                          setData(
                            data.map((o) =>
                              o.num !== d.num
                                ? { ...o }
                                : { ...o, frstNa: e.target.value }
                            )
                          );
                        }}
                      />
                    </td>
                    <td className="p-1">
                      Last Name
                      <input
                        type="text"
                        required
                        placeholder="Last Name"
                        className="border px-2 py-1 rounded w-full"
                        value={d.lastNa}
                        onChange={(e) => {
                          setData(
                            data.map((o) =>
                              o.num !== d.num
                                ? { ...o }
                                : { ...o, lastNa: e.target.value }
                            )
                          );
                        }}
                      />
                    </td>
                    <td className="p-1">
                      Gender
                      <select
                        className="border px-2 py-1 rounded w-full"
                        value={d.gender}
                        onChange={(e) => {
                          setData(
                            data.map((o) =>
                              o.num !== d.num
                                ? { ...o }
                                : { ...o, gender: e.target.value }
                            )
                          );
                        }}
                      >
                        <option value={"Male"}>Male</option>
                        <option value={"Female"}>Female</option>
                      </select>
                    </td>
                    <td className="p-1">
                      Birth Date
                      <input
                        type="date"
                        required
                        placeholder="Birth Date"
                        className="border px-2 py-1 rounded w-32"
                        value={d.brthDa}
                        onChange={(e) => {
                          setData(
                            data.map((o) =>
                              o.num !== d.num
                                ? { ...o }
                                : { ...o, brthDa: e.target.value }
                            )
                          );
                        }}
                      />
                    </td>
                    <td className="p-1">
                      Phone Number
                      <input
                        type="text"
                        placeholder="Phone Number"
                        className="border px-2 py-1 rounded w-full"
                        value={d.phonNu}
                        onChange={(e) => {
                          setData(
                            data.map((o) =>
                              o.num !== d.num
                                ? { ...o }
                                : { ...o, phonNu: e.target.value }
                            )
                          );
                        }}
                      />
                    </td>
                    <td className="p-1">
                      Passport Number
                      <input
                        type="text"
                        required
                        placeholder="Passport Number"
                        className="border px-2 py-1 rounded w-full"
                        value={d.passNu}
                        onChange={(e) => {
                          setData(
                            data.map((o) =>
                              o.num !== d.num
                                ? { ...o }
                                : { ...o, passNu: e.target.value }
                            )
                          );
                        }}
                      />
                    </td>
                    <td className="p-1" rowSpan={2}>
                      Passport Photo
                      <img
                        src={d.passIm}
                        className="w-20 h-20 cursor-pointer"
                        onClick={() => {
                          document
                            .getElementById("choose" + d.num.toString())
                            .click();
                        }}
                      />
                      <input
                        type="file"
                        id={"choose" + d.num.toString()}
                        placeholder=""
                        className="border px-2 py-1 rounded hidden"
                        onChange={async (e) => {
                          if (e.target.files) {
                            const base64 = await toBase64(e.target.files[0]);
                            setData(
                              data.map((o) =>
                                o.num !== d.num
                                  ? { ...o }
                                  : { ...o, passIm: base64 }
                              )
                            );
                          }
                        }}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td className="p-1" align="center"></td>
                    <td className="p-1" colSpan={3}>
                      <div className="flex w-full gap-2">
                        <div>
                          Arrival Date Time
                          <div className="flex gap-1">
                            <input
                              type="date"
                              placeholder="Arrival Date"
                              className="border px-2 py-1 rounded w-32"
                              value={d.arrvDt}
                              onChange={(e) => {
                                setData(
                                  data.map((o) =>
                                    o.num !== d.num
                                      ? { ...o }
                                      : { ...o, arrvDt: e.target.value }
                                  )
                                );
                              }}
                            />
                            <input
                              type="time"
                              placeholder="Arrival Time"
                              className="border px-2 py-1 rounded w-24"
                              value={d.arrvTi}
                              onChange={(e) => {
                                setData(
                                  data.map((o) =>
                                    o.num !== d.num
                                      ? { ...o }
                                      : { ...o, arrvTi: e.target.value }
                                  )
                                );
                              }}
                            />
                          </div>
                        </div>
                        <div>
                          Flight Number
                          <input
                            type="text"
                            placeholder="Flight Number"
                            className="border px-2 py-1 rounded w-full"
                            value={d.arrvFl}
                            onChange={(e) => {
                              setData(
                                data.map((o) =>
                                  o.num !== d.num
                                    ? { ...o }
                                    : { ...o, arrvFl: e.target.value }
                                )
                              );
                            }}
                          />
                        </div>
                      </div>
                    </td>
                    <td className="p-1" colSpan={3}>
                      <div className="flex w-full gap-2">
                        <div>
                          Departure Date Time
                          <div className="flex gap-1">
                            <input
                              type="date"
                              placeholder="Departure Date"
                              className="border px-2 py-1 rounded w-32"
                              value={d.deptDt}
                              onChange={(e) => {
                                setData(
                                  data.map((o) =>
                                    o.num !== d.num
                                      ? { ...o }
                                      : { ...o, deptDt: e.target.value }
                                  )
                                );
                              }}
                            />
                            <input
                              type="time"
                              placeholder="Departure Time"
                              className="border px-2 py-1 rounded w-24"
                              value={d.deptTi}
                              onChange={(e) => {
                                setData(
                                  data.map((o) =>
                                    o.num !== d.num
                                      ? { ...o }
                                      : { ...o, deptTi: e.target.value }
                                  )
                                );
                              }}
                            />
                          </div>
                        </div>
                        <div>
                          Flight Number
                          <input
                            type="text"
                            placeholder="Flight Number"
                            className="border px-2 py-1 rounded w-full"
                            value={d.deptFl}
                            onChange={(e) => {
                              setData(
                                data.map((o) =>
                                  o.num !== d.num
                                    ? { ...o }
                                    : { ...o, deptFl: e.target.value }
                                )
                              );
                            }}
                          />
                        </div>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td colSpan={10} className="pb-6">
                      <hr />
                    </td>
                  </tr>
                </Fragment>
              );
            })}
            <tr>
              <td colSpan={7}></td>
              <td colSpan={2}>
                <input
                  type="submit"
                  value="Register"
                  className="MyBgColorGreen rounded py-2 w-full cursor-pointer MyWhite"
                />
              </td>
            </tr>
          </tbody>
        </table>
      </form>
    </div>
  );
}
