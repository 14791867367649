import { useContext, useEffect, useState } from "react";
import ToastMessage from "../toast";
import axios from "axios";
import { API } from "../../utils/config";
import TourAdd from "./tour-add";
import TourEdit from "./tour-edit";
import Loading from "../loading";
import UserContext from "../../utils/context";

export default function TourList() {
  const [selected, setSelected] = useState(null);
  const [isload, setIsload] = useState(0);
  const [showAdd, setShowAdd] = useState(false);
  const [showEdit, setShowEdit] = useState(false);
  const context = useContext(UserContext);
  const [tours, setTours] = useState([]);
  const [loading, setLoading] = useState(false);

  const deleteTour = async (tid) => {
    if (window.confirm("Are you sure to delete?")) {
      try {
        setLoading(true);
        let data = new FormData();
        data.append("oper", "delete");
        data.append("token", context.token);
        data.append("tid", tid);
        const res = await axios.post(API + "tour.php", data);
        if (res.data.success) {
          setIsload(isload + 1);
        } else {
          ToastMessage({ type: "error", message: res.data.message });
        }
        setLoading(false);
      } catch (err) {
        setLoading(false);
        console.log(err);
        ToastMessage({ type: "error", message: "Error" });
      }
    }
  };

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        let data = new FormData();
        data.append("oper", "list");
        data.append("token", context.token);
        const res = await axios.post(API + "tour.php", data);
        if (res.data.success) {
          setTours(JSON.parse(res.data.data));
        } else {
          ToastMessage({ type: "error", message: res.data.message });
        }
        setLoading(false);
      } catch (err) {
        console.log(err);
        setLoading(false);
        ToastMessage({ type: "error", message: "Error" });
      }
    })();
  }, [isload]);

  return (
    <div className="w-full mt-3">
      <div className="w-full text-right p-2">
        {loading && <Loading />}
        {showAdd && (
          <TourAdd isload={isload} setIsload={setIsload} setShow={setShowAdd} />
        )}
        {showEdit && (
          <TourEdit
            tour={selected}
            isload={isload}
            setIsload={setIsload}
            setShow={setShowEdit}
          />
        )}
        <button
          onClick={() => {
            setShowAdd(true);
          }}
          className="bg-blue-500 border border-blue-600 rounded py-1 px-3 text-white"
        >
          Add new Tour
        </button>
      </div>
      <table width="100%" border={1}>
        <thead>
          <tr className="border-b border-gray-300">
            <th align="left">#</th>
            <th align="left">Tour Name</th>
            <th align="left">Description</th>
            <th align="center">Edit</th>
            <th align="center">Delete</th>
          </tr>
        </thead>
        <tbody>
          {tours.map((e, i) => {
            return (
              <tr key={i} className="border-b border-gray-300">
                <td>{i + 1}</td>
                <td>{e.tourNa}</td>
                <td>{e.tourDs}</td>
                <td align="center" className="p-1">
                  <button
                    onClick={() => {
                      setSelected(e);
                      setShowEdit(true);
                    }}
                    className="bg-green-500 border border-green-600 rounded py-1 px-3 text-white"
                  >
                    Edit
                  </button>
                </td>
                <td align="center" className="p-1">
                  <button
                    className="bg-red-500 border border-red-600 rounded py-1 px-3 text-white"
                    onClick={() => {
                      deleteTour(e.tid);
                    }}
                  >
                    Delete
                  </button>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
}
