import ToastMessage from "./toast";
import { API } from "../utils/config";
import UserContext, { useAppContext } from "../utils/context";
import axios from "axios";
import { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";

export default function LoginPage() {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  const route = useNavigate();
  const context = useContext(UserContext);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      let data = new FormData();
      data.append("oper", "login");
      data.append("uname", username);
      data.append("upass", password);
      const res = await axios.post(API + "login.php", data);
      if (res.data.success) {
        const parsed = await JSON.parse(res.data.data);

        context.setToken(parsed.token);
        context.setEmail(parsed.email);
        context.setCountry(parsed.country);
        context.setAdmin(parsed.admin.toString());
        context.setExpire(parsed.expire);
        route("/dashboard");
      } else {
        ToastMessage({ type: "error", message: res.data?.message });
      }
    } catch (error) {
      ToastMessage({ type: "error", message: "Error" });
    }
  };

  return (
    <div className="w-full p-3 text-2xl">
      <div className="my-3 w-full flex">
        <div className="min-w-40 p-2">E-Mail : </div>
        <input
          type="email"
          name="email"
          required
          value={username}
          onChange={(e) => {
            setUsername(e.target.value);
          }}
          placeholder="E-Mail address"
          className="border p-2 rounded w-full text-gray-900"
        />
      </div>
      <div className="my-3 w-full flex">
        <div className="min-w-40 p-2">Password : </div>
        <input
          type="password"
          name="passd"
          required
          value={password}
          onChange={(e) => {
            setPassword(e.target.value);
          }}
          placeholder="Password"
          className="border p-2 rounded w-full text-gray-900"
        />
      </div>
      <div className="my-3 w-full flex justify-end">
        <input
          type="submit"
          value="Login"
          onClick={handleSubmit}
          className="bg-green-600 border border-green-700 rounded py-4 w-full cursor-pointer"
        />
      </div>
    </div>
  );
}
