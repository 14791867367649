import { FormEventHandler, useContext, useState } from "react";
import ToastMessage from "../toast";

import { API } from "../../utils/config";
import axios from "axios";
import Loading from "../loading";
import UserContext from "../../utils/context";

export default function EventAdd({ isload, setIsload, setShow }) {
  const [evntNa, setEvntNa] = useState("");
  const [evntSt, setEvntSt] = useState("");
  const [evntEd, setEvntEd] = useState("");
  const [descr, setDescr] = useState("");
  const context = useContext(UserContext);
  const [loading, setLoading] = useState(false);

  const SaveEvent = async (e) => {
    e.preventDefault();
    if (evntNa === "") {
      ToastMessage({ type: "error", message: "Insert event name" });
      return;
    }
    if (evntSt === "") {
      ToastMessage({ type: "error", message: "Insert event start" });
      return;
    }
    if (evntEd === "") {
      ToastMessage({ type: "error", message: "Insert event end" });
      return;
    }
    let formdata = new FormData();
    formdata.append("evntNa", evntNa);
    formdata.append("evntSt", evntSt);
    formdata.append("evntEd", evntEd);
    formdata.append("descr", descr);
    formdata.append("token", context.token);
    formdata.append("oper", "add");
    try {
      setLoading(true);
      const res = await axios.post(API + "event.php", formdata);
      if (res.data.success) {
        setIsload(isload + 1);
        setShow(false);
      } else {
        ToastMessage({ type: "error", message: res.data.message });
      }
      setLoading(false);
    } catch (err) {
      setLoading(false);
      console.log(err);
      ToastMessage({ type: "error", message: "Error" });
    }
  };

  return (
    <div className="bg-gray-50 w-full h-full absolute top-0 left-0 pt-40 bg-opacity-90">
      <div className="bg-white max-w-5xl mx-auto p-10 text-2xl rounded border shadow text-black">
        <div className="w-full text-left">
          {loading && <Loading />}
          <form onSubmit={SaveEvent}>
            <div className="my-3 w-full flex">
              <div className="min-w-40 p-2">Name : </div>
              <input
                type="text"
                name="evntNa"
                value={evntNa}
                onChange={(e) => {
                  setEvntNa(e.target.value);
                }}
                required
                placeholder="Event Name"
                className="border p-2 rounded w-full"
              />
            </div>
            <div className="my-3 w-full flex">
              <div className="min-w-40 p-2">Start : </div>
              <input
                type="text"
                name="evntSt"
                value={evntSt}
                onChange={(e) => {
                  setEvntSt(e.target.value);
                }}
                required
                placeholder="20th DEC etc"
                className="border p-2 rounded w-full"
              />
            </div>
            <div className="my-3 w-full flex">
              <div className="min-w-40 p-2">End : </div>
              <input
                type="text"
                name="evntEd"
                value={evntEd}
                onChange={(e) => {
                  setEvntEd(e.target.value);
                }}
                required
                placeholder="27th DEC 2023 etc"
                className="border p-2 rounded w-full"
              />
            </div>
            <div className="my-3 w-full flex">
              <div className="min-w-40 p-2">Description : </div>
              <input
                type="text"
                name="descr"
                value={descr}
                onChange={(e) => {
                  setDescr(e.target.value);
                }}
                placeholder="Description"
                className="border p-2 rounded w-full"
              />
            </div>
            <div className="my-3 w-full flex justify-end gap-4">
              <input
                type="button"
                value="Cancel"
                onClick={() => {
                  setShow(false);
                }}
                className="bg-red-600 border border-red-700 rounded py-3 px-10 cursor-pointer"
              />
              <input
                type="submit"
                value="Create Event"
                className="bg-green-600 border border-green-700 rounded py-3 px-10 cursor-pointer"
              />
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
