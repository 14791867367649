import React, { useContext, useEffect, useState } from "react";
import UserContext from "../../utils/context";
import { SlMagnifierAdd, SlMagnifierRemove } from "react-icons/sl";
import Loading from "../loading";
import { API } from "../../utils/config";
import ToastMessage from "../toast";
import axios from "axios";
import BookingFoodAdd from "./BookingFoodAdd";

export default function BookingFoods() {
  const context = useContext(UserContext);
  const [loading, setLoading] = useState(false);
  const [fadd, setFadd] = useState(false);
  const [food, setFood] = useState([]);
  const [isload, setIsload] = useState(0);
  var sum = 0;

  const EventFood = async () => {
    try {
      setLoading(true);
      let frmdata = new FormData();
      frmdata.append("oper", "food");
      frmdata.append("eventid", "1");
      frmdata.append("userid", context.userid);
      frmdata.append("token", context.token);
      const res = await axios.post(API + "register.php", frmdata);
      if (res.data.success) {
        if (JSON.parse(res.data.data)?.length > 0) {
          const parsed = JSON.parse(res.data.data);
          let tmp = [];
          await Promise.all(
            parsed?.map((e, i) => {
              tmp.push({
                num: i + 1,
                foodNa: e.foodNa,
                st: e.st,
                ed: e.ed,
                foodNu: e.foodNu,
                price: e.price,
                id: e.id,
              });
            })
          );
          setFood(tmp);
        }
      } else {
        ToastMessage({ type: "error", message: res.data.message });
      }
      setLoading(false);
    } catch (err) {
      setLoading(false);
      console.log(err);
      ToastMessage({ type: "error", message: "Error" });
    }
  };
  const delFood = async (id) => {
    try {
      if (window.confirm("Are you sure?") === true) {
        setLoading(true);
        let frmdata = new FormData();
        frmdata.append("oper", "fooddel");
        frmdata.append("eventid", "1");
        frmdata.append("id", id);
        frmdata.append("userid", context.userid);
        frmdata.append("token", context.token);
        const res = await axios.post(API + "register.php", frmdata);
        if (res.data.success) {
          setIsload(isload + 1);
          ToastMessage({ type: "success", message: res.data.message });
          setLoading(false);
        } else {
          ToastMessage({ type: "error", message: res.data.message });
          setLoading(false);
        }
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
      ToastMessage({ type: "error", message: "Error" });
    }
  };

  useEffect(() => {
    (async () => {
      await EventFood();
    })();
  }, [isload, fadd]);

  return (
    <div className="w-full mt-5">
      {loading && <Loading />}
      {fadd && <BookingFoodAdd hide={setFadd} />}
      <div className="w-full flex">
        <div className="w-20">
          <h2 className="font-bold">Meals</h2>
        </div>
        <div
          className="flex cursor-pointer "
          onClick={() => {
            setFadd(true);
          }}
        >
          <SlMagnifierAdd className="cursor-pointer MyColorGreen" size={20} />
          <div className="ml-1 bg-white p-1 border border-green-400 rounded-md text-xs cursor-pointer ">
            Add Meal booking
          </div>
        </div>
      </div>
      <table className="w-full table-auto">
        <thead>
          <tr className="border-b">
            <th className="text-center">#</th>
            <th className="text-left">Meal</th>
            <th className="text-center">Number of Delegation</th>
            <th className="text-center">Start date</th>
            <th className="text-center">End date</th>
            <th className="text-right">Price</th>
            <th align="center"></th>
          </tr>
        </thead>
        <tbody>
          {food?.map((h, i) => {
            sum += h.price;
            return (
              <tr key={i} className="border-b">
                <td className="text-center">{i + 1}</td>
                <td>{h.foodNa}</td>
                <td className="text-center">{h.foodNu}</td>
                <td className="text-center">{h.st}</td>
                <td className="text-center">{h.ed}</td>
                <td className="text-right">{h.price} USD</td>
                <td className="p-1" align="center">
                  <SlMagnifierRemove
                    className="cursor-pointer MyColorRed"
                    onClick={() => {
                      delFood(h.id);
                    }}
                  />
                </td>
              </tr>
            );
          })}
        </tbody>
        <tfoot>
          <tr>
            <td colSpan={6} className="text-right font-bold">
              Total : {sum} USD
            </td>
            <td></td>
          </tr>
        </tfoot>
      </table>
    </div>
  );
}
