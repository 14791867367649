import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { API } from "../../utils/config";
import ToastMessage from "../toast";
import UserContext from "../../utils/context";
import Loading from "../loading";

export default function Profile() {
  const [email, setEmail] = useState("");
  const [frstNa, setFrstNa] = useState("");
  const [lastNa, setLastNa] = useState("");
  const [gender, setGender] = useState("");
  const [brthDa, setBrthDa] = useState("");
  const [passNu, setPassNu] = useState("");
  const [phonNu, setPhonNu] = useState("");
  const [passIm, setPassIm] = useState("");
  const [nation, setNation] = useState("");
  const [loading, setLoading] = useState(false);
  const context = useContext(UserContext);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      let data = new FormData();
      data.append("oper", "save");
      data.append("email", email);
      data.append("frstNa", frstNa);
      data.append("lastNa", lastNa);
      data.append("gender", gender);
      data.append("brthDa", brthDa);
      data.append("passNu", passNu);
      data.append("phonNu", phonNu);
      data.append("nation", nation);
      const res = await axios.post(API + "profile.php", data);
      if (res.data?.success) {
        ToastMessage({ type: "success", message: res.data?.message });
      } else {
        ToastMessage({ type: "error", message: res.data?.message });
      }
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
      ToastMessage({ type: "error", message: "Error" });
    }
  };

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        let data = new FormData();
        data.append("oper", "get");
        data.append("email", context?.email);
        const res = await axios.post(API + "profile.php", data);
        if (res.data?.success) {
          const info = JSON.parse(res.data.data);
          setEmail(info?.email);
          setFrstNa(info?.frstNa);
          setLastNa(info?.lastNa);
          setPhonNu(info?.phonNu);
          setBrthDa(info?.brthDa);
          setGender(info?.gender);
          setPassNu(info?.passNu);
          setNation(info?.nation);
        } else {
          ToastMessage({ type: "error", message: res.data?.message });
        }
        setLoading(false);
      } catch (error) {
        console.log(error);
        setLoading(false);
        ToastMessage({ type: "error", message: "Error" });
      }
    })();
  }, []);

  return (
    <div className=" min-w-80 max-w-[450px] px-7">
      {loading && <Loading />}
      <form onSubmit={handleSubmit}>
        <div className="my-3 w-full flex">
          <div className="min-w-36 p-2">E-Mail : </div>
          <input
            type="email"
            name="email"
            required
            value={email}
            readOnly
            placeholder="E-Mail address"
            className="border px-2 py-0 rounded w-full text-gray-900"
          />
        </div>
        <div className="my-3 w-full flex ">
          <div className="min-w-36 p-2">First Name : </div>
          <input
            type="text"
            name="frstNa"
            required
            value={frstNa}
            onChange={(e) => {
              setFrstNa(e.target.value);
            }}
            placeholder="First Name"
            className="border px-2 py-0 rounded w-full text-gray-900"
          />
        </div>
        <div className="my-3 w-full flex ">
          <div className="min-w-36 p-2">Last Name : </div>
          <input
            type="text"
            name="lastNa"
            required
            value={lastNa}
            onChange={(e) => {
              setLastNa(e.target.value);
            }}
            placeholder="Last Name"
            className="border px-2 py-0 rounded w-full text-gray-900"
          />
        </div>
        <div className="my-3 w-full flex">
          <div className="min-w-36 p-2">Birth Date: </div>
          <input
            type="date"
            name="brthDa"
            required
            value={brthDa}
            onChange={(e) => {
              setBrthDa(e.target.value);
            }}
            placeholder="Birth Date"
            className="border px-2 py-0 rounded w-full text-gray-900"
          />
        </div>
        <div className="my-3 w-full flex ">
          <div className="min-w-36 p-2">Gender: </div>
          <select
            name="gender"
            defaultValue={gender}
            onChange={(e) => {
              setBrthDa(e.target.value);
            }}
            className="border px-2 py-0 rounded w-full text-gray-900"
          >
            <option value={"Male"}>Male</option>
            <option value={"Female"}>Female</option>
          </select>
        </div>
        <div className="my-3 w-full flex ">
          <div className="min-w-36 p-2">Passport №: </div>
          <input
            type="text"
            name="passNu"
            required
            value={passNu}
            onChange={(e) => {
              setPassNu(e.target.value);
            }}
            placeholder="Passport Number"
            className="border px-2 py-0 rounded w-full text-gray-900"
          />
        </div>
        <div className="my-3 w-full flex ">
          <div className="min-w-36 p-2">Nationality: </div>
          <input
            type="text"
            name="nation"
            required
            value={nation}
            onChange={(e) => {
              setNation(e.target.value);
            }}
            placeholder="Nationality"
            className="border px-2 py-0 rounded w-full text-gray-900"
          />
        </div>
        <div className="my-3 w-full flex ">
          <div className="min-w-36 p-2">Phone Number: </div>
          <input
            type="text"
            name="phonNu"
            required
            value={phonNu}
            onChange={(e) => {
              setPhonNu(e.target.value);
            }}
            placeholder="Phone Number"
            className="border px-2 py-0 rounded w-full text-gray-900"
          />
        </div>
        <div className="my-3 w-full flex">
          <input
            type="submit"
            value="Save"
            className="MyBgColorGreen rounded py-2 w-full cursor-pointer MyWhite"
          />
        </div>
      </form>
    </div>
  );
}
